import { ApolloClient } from 'apollo-client';
import { DefaultOptions } from 'apollo-client/ApolloClient';
import { ApolloLink } from 'apollo-link';

import cache from './cache';
import { errorLink, httpLink, omitTypename } from './links';

import { APP_ENV } from '../../AppConfig';
import authLink from './links/auth';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all'
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  },
  mutate: {
    errorPolicy: 'all'
  }
};

const link = ApolloLink.from([authLink, omitTypename, httpLink]);

const client = new ApolloClient({
  link: errorLink.concat(link),
  cache,
  defaultOptions,
  connectToDevTools: APP_ENV !== 'prod'
});

export default client;
