import { GoogleMap, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api';
import ErrorAlert from 'components/ErrorAlert';
import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { PINGS } from './queries';

function PingMap() {
  useEffect(() => {
    document.title = 'Pings | Evil Genius Labs';
  }, []);

  const { loading, error, data } = useQuery(PINGS);

  if (loading)
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );

  if (error) return <ErrorAlert error={error} />;

  const { admin } = data;
  const { pings } = admin;
  const { items } = pings;

  return (
    <LoadScript googleMapsApiKey="AIzaSyC3pBYELHNtSfc5cXTuQafwoNMHZbrulfY">
      <GoogleMap mapContainerStyle={{ width: '100%', height: '800px' }} center={{ lat: 0, lng: 0 }} zoom={2}>
        <MarkerClusterer
          gridSize={20}
          imagePath="https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
        >
          {(clusterer) =>
            items.map((ping, i) => {
              const { deviceName, sourceIpData } = ping;
              const { latitude, longitude } = sourceIpData;
              return (
                <Marker key={i} position={{ lat: latitude, lng: longitude }} clusterer={clusterer} title={deviceName} />
              );
            })
          }
        </MarkerClusterer>
      </GoogleMap>
    </LoadScript>
  );
}

export default PingMap;
