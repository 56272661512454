import React from 'react';
import PropertyList from './PropertyList';

interface Props {
  error: any;
}

function ErrorAlert(props: Props) {
  const { error = {} } = props;

  if (!error) return null;

  console.log({error});

  return (
    <>
      <div className="alert alert-danger alert-dismissible fade show" role="alert">
        {error.name ? <h5 className="alert-heading">{error.name}</h5> : null}
        <p className="mb-0">{error.message}</p>
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <p>
        <button
          className="btn btn-outline-secondary"
          type="button"
          data-toggle="collapse"
          data-target="#errorCollapse"
          aria-expanded="false"
          aria-controls="errorCollapse"
        >
          Details
        </button>
      </p>
      <div id="errorCollapse" className="collapse">
        <div className="card-body">
          <PropertyList object={{
            error_message: error.message,
            response_message: error?.response?.data?.message || null,
            stack_trace: <pre>{error.stack}</pre>
          }} />
        </div>
      </div>
    </>
  );
}

export default ErrorAlert;
