import { setContext } from 'apollo-link-context';
import { getAuthorizationHeader } from 'utils/auth';

const authLink = setContext((request, previousContext) => {
  return new Promise(async (resolve, reject) => {
    const authorization = await getAuthorizationHeader();

    if (authorization) {
      resolve({
        headers: {
          authorization
        }
      });
    } else resolve({});
  });
});

export default authLink;
