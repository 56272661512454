export const countries = [
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Afghanistan, Islamic Republic of',
    countryNumber: 4,
    threeLetterCountryCode: 'AFG',
    twoLetterCountryCode: 'AF'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Albania, Republic of',
    countryNumber: 8,
    threeLetterCountryCode: 'ALB',
    twoLetterCountryCode: 'AL'
  },
  {
    continentCode: 'AN',
    continentName: 'Antarctica',
    countryName: 'Antarctica (the territory South of 60 deg S)',
    countryNumber: 10,
    threeLetterCountryCode: 'ATA',
    twoLetterCountryCode: 'AQ'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: "Algeria, People's Democratic Republic of",
    countryNumber: 12,
    threeLetterCountryCode: 'DZA',
    twoLetterCountryCode: 'DZ'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'American Samoa',
    countryNumber: 16,
    threeLetterCountryCode: 'ASM',
    twoLetterCountryCode: 'AS'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Andorra, Principality of',
    countryNumber: 20,
    threeLetterCountryCode: 'AND',
    twoLetterCountryCode: 'AD'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Angola, Republic of',
    countryNumber: 24,
    threeLetterCountryCode: 'AGO',
    twoLetterCountryCode: 'AO'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Antigua and Barbuda',
    countryNumber: 28,
    threeLetterCountryCode: 'ATG',
    twoLetterCountryCode: 'AG'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Azerbaijan, Republic of',
    countryNumber: 31,
    threeLetterCountryCode: 'AZE',
    twoLetterCountryCode: 'AZ'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Azerbaijan, Republic of',
    countryNumber: 31,
    threeLetterCountryCode: 'AZE',
    twoLetterCountryCode: 'AZ'
  },
  {
    continentCode: 'SA',
    continentName: 'South America',
    countryName: 'Argentina, Argentine Republic',
    countryNumber: 32,
    threeLetterCountryCode: 'ARG',
    twoLetterCountryCode: 'AR'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Australia, Commonwealth of',
    countryNumber: 36,
    threeLetterCountryCode: 'AUS',
    twoLetterCountryCode: 'AU'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Austria, Republic of',
    countryNumber: 40,
    threeLetterCountryCode: 'AUT',
    twoLetterCountryCode: 'AT'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Bahamas, Commonwealth of the',
    countryNumber: 44,
    threeLetterCountryCode: 'BHS',
    twoLetterCountryCode: 'BS'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Bahrain, Kingdom of',
    countryNumber: 48,
    threeLetterCountryCode: 'BHR',
    twoLetterCountryCode: 'BH'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: "Bangladesh, People's Republic of",
    countryNumber: 50,
    threeLetterCountryCode: 'BGD',
    twoLetterCountryCode: 'BD'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Armenia, Republic of',
    countryNumber: 51,
    threeLetterCountryCode: 'ARM',
    twoLetterCountryCode: 'AM'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Armenia, Republic of',
    countryNumber: 51,
    threeLetterCountryCode: 'ARM',
    twoLetterCountryCode: 'AM'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Barbados',
    countryNumber: 52,
    threeLetterCountryCode: 'BRB',
    twoLetterCountryCode: 'BB'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Belgium, Kingdom of',
    countryNumber: 56,
    threeLetterCountryCode: 'BEL',
    twoLetterCountryCode: 'BE'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Bermuda',
    countryNumber: 60,
    threeLetterCountryCode: 'BMU',
    twoLetterCountryCode: 'BM'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Bhutan, Kingdom of',
    countryNumber: 64,
    threeLetterCountryCode: 'BTN',
    twoLetterCountryCode: 'BT'
  },
  {
    continentCode: 'SA',
    continentName: 'South America',
    countryName: 'Bolivia, Republic of',
    countryNumber: 68,
    threeLetterCountryCode: 'BOL',
    twoLetterCountryCode: 'BO'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Bosnia and Herzegovina',
    countryNumber: 70,
    threeLetterCountryCode: 'BIH',
    twoLetterCountryCode: 'BA'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Botswana, Republic of',
    countryNumber: 72,
    threeLetterCountryCode: 'BWA',
    twoLetterCountryCode: 'BW'
  },
  {
    continentCode: 'AN',
    continentName: 'Antarctica',
    countryName: 'Bouvet Island (Bouvetoya)',
    countryNumber: 74,
    threeLetterCountryCode: 'BVT',
    twoLetterCountryCode: 'BV'
  },
  {
    continentCode: 'SA',
    continentName: 'South America',
    countryName: 'Brazil, Federative Republic of',
    countryNumber: 76,
    threeLetterCountryCode: 'BRA',
    twoLetterCountryCode: 'BR'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Belize',
    countryNumber: 84,
    threeLetterCountryCode: 'BLZ',
    twoLetterCountryCode: 'BZ'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'British Indian Ocean Territory (Chagos Archipelago)',
    countryNumber: 86,
    threeLetterCountryCode: 'IOT',
    twoLetterCountryCode: 'IO'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Solomon Islands',
    countryNumber: 90,
    threeLetterCountryCode: 'SLB',
    twoLetterCountryCode: 'SB'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'British Virgin Islands',
    countryNumber: 92,
    threeLetterCountryCode: 'VGB',
    twoLetterCountryCode: 'VG'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Brunei Darussalam',
    countryNumber: 96,
    threeLetterCountryCode: 'BRN',
    twoLetterCountryCode: 'BN'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Bulgaria, Republic of',
    countryNumber: 100,
    threeLetterCountryCode: 'BGR',
    twoLetterCountryCode: 'BG'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Myanmar, Union of',
    countryNumber: 104,
    threeLetterCountryCode: 'MMR',
    twoLetterCountryCode: 'MM'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Burundi, Republic of',
    countryNumber: 108,
    threeLetterCountryCode: 'BDI',
    twoLetterCountryCode: 'BI'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Belarus, Republic of',
    countryNumber: 112,
    threeLetterCountryCode: 'BLR',
    twoLetterCountryCode: 'BY'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Cambodia, Kingdom of',
    countryNumber: 116,
    threeLetterCountryCode: 'KHM',
    twoLetterCountryCode: 'KH'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Cameroon, Republic of',
    countryNumber: 120,
    threeLetterCountryCode: 'CMR',
    twoLetterCountryCode: 'CM'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Canada',
    countryNumber: 124,
    threeLetterCountryCode: 'CAN',
    twoLetterCountryCode: 'CA'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Cape Verde, Republic of',
    countryNumber: 132,
    threeLetterCountryCode: 'CPV',
    twoLetterCountryCode: 'CV'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Cayman Islands',
    countryNumber: 136,
    threeLetterCountryCode: 'CYM',
    twoLetterCountryCode: 'KY'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Central African Republic',
    countryNumber: 140,
    threeLetterCountryCode: 'CAF',
    twoLetterCountryCode: 'CF'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Sri Lanka, Democratic Socialist Republic of',
    countryNumber: 144,
    threeLetterCountryCode: 'LKA',
    twoLetterCountryCode: 'LK'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Chad, Republic of',
    countryNumber: 148,
    threeLetterCountryCode: 'TCD',
    twoLetterCountryCode: 'TD'
  },
  {
    continentCode: 'SA',
    continentName: 'South America',
    countryName: 'Chile, Republic of',
    countryNumber: 152,
    threeLetterCountryCode: 'CHL',
    twoLetterCountryCode: 'CL'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: "China, People's Republic of",
    countryNumber: 156,
    threeLetterCountryCode: 'CHN',
    twoLetterCountryCode: 'CN'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Taiwan',
    countryNumber: 158,
    threeLetterCountryCode: 'TWN',
    twoLetterCountryCode: 'TW'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Christmas Island',
    countryNumber: 162,
    threeLetterCountryCode: 'CXR',
    twoLetterCountryCode: 'CX'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Cocos (Keeling) Islands',
    countryNumber: 166,
    threeLetterCountryCode: 'CCK',
    twoLetterCountryCode: 'CC'
  },
  {
    continentCode: 'SA',
    continentName: 'South America',
    countryName: 'Colombia, Republic of',
    countryNumber: 170,
    threeLetterCountryCode: 'COL',
    twoLetterCountryCode: 'CO'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Comoros, Union of the',
    countryNumber: 174,
    threeLetterCountryCode: 'COM',
    twoLetterCountryCode: 'KM'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Mayotte',
    countryNumber: 175,
    threeLetterCountryCode: 'MYT',
    twoLetterCountryCode: 'YT'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Congo, Republic of the',
    countryNumber: 178,
    threeLetterCountryCode: 'COG',
    twoLetterCountryCode: 'CG'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Congo, Democratic Republic of the',
    countryNumber: 180,
    threeLetterCountryCode: 'COD',
    twoLetterCountryCode: 'CD'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Cook Islands',
    countryNumber: 184,
    threeLetterCountryCode: 'COK',
    twoLetterCountryCode: 'CK'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Costa Rica, Republic of',
    countryNumber: 188,
    threeLetterCountryCode: 'CRI',
    twoLetterCountryCode: 'CR'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Croatia, Republic of',
    countryNumber: 191,
    threeLetterCountryCode: 'HRV',
    twoLetterCountryCode: 'HR'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Cuba, Republic of',
    countryNumber: 192,
    threeLetterCountryCode: 'CUB',
    twoLetterCountryCode: 'CU'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Cyprus, Republic of',
    countryNumber: 196,
    threeLetterCountryCode: 'CYP',
    twoLetterCountryCode: 'CY'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Cyprus, Republic of',
    countryNumber: 196,
    threeLetterCountryCode: 'CYP',
    twoLetterCountryCode: 'CY'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Czech Republic',
    countryNumber: 203,
    threeLetterCountryCode: 'CZE',
    twoLetterCountryCode: 'CZ'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Benin, Republic of',
    countryNumber: 204,
    threeLetterCountryCode: 'BEN',
    twoLetterCountryCode: 'BJ'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Denmark, Kingdom of',
    countryNumber: 208,
    threeLetterCountryCode: 'DNK',
    twoLetterCountryCode: 'DK'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Dominica, Commonwealth of',
    countryNumber: 212,
    threeLetterCountryCode: 'DMA',
    twoLetterCountryCode: 'DM'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Dominican Republic',
    countryNumber: 214,
    threeLetterCountryCode: 'DOM',
    twoLetterCountryCode: 'DO'
  },
  {
    continentCode: 'SA',
    continentName: 'South America',
    countryName: 'Ecuador, Republic of',
    countryNumber: 218,
    threeLetterCountryCode: 'ECU',
    twoLetterCountryCode: 'EC'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'El Salvador, Republic of',
    countryNumber: 222,
    threeLetterCountryCode: 'SLV',
    twoLetterCountryCode: 'SV'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Equatorial Guinea, Republic of',
    countryNumber: 226,
    threeLetterCountryCode: 'GNQ',
    twoLetterCountryCode: 'GQ'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Ethiopia, Federal Democratic Republic of',
    countryNumber: 231,
    threeLetterCountryCode: 'ETH',
    twoLetterCountryCode: 'ET'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Eritrea, State of',
    countryNumber: 232,
    threeLetterCountryCode: 'ERI',
    twoLetterCountryCode: 'ER'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Estonia, Republic of',
    countryNumber: 233,
    threeLetterCountryCode: 'EST',
    twoLetterCountryCode: 'EE'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Faroe Islands',
    countryNumber: 234,
    threeLetterCountryCode: 'FRO',
    twoLetterCountryCode: 'FO'
  },
  {
    continentCode: 'SA',
    continentName: 'South America',
    countryName: 'Falkland Islands (Malvinas)',
    countryNumber: 238,
    threeLetterCountryCode: 'FLK',
    twoLetterCountryCode: 'FK'
  },
  {
    continentCode: 'AN',
    continentName: 'Antarctica',
    countryName: 'South Georgia and the South Sandwich Islands',
    countryNumber: 239,
    threeLetterCountryCode: 'SGS',
    twoLetterCountryCode: 'GS'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Fiji, Republic of the Fiji Islands',
    countryNumber: 242,
    threeLetterCountryCode: 'FJI',
    twoLetterCountryCode: 'FJ'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Finland, Republic of',
    countryNumber: 246,
    threeLetterCountryCode: 'FIN',
    twoLetterCountryCode: 'FI'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: '\u00c5land Islands',
    countryNumber: 248,
    threeLetterCountryCode: 'ALA',
    twoLetterCountryCode: 'AX'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'France, French Republic',
    countryNumber: 250,
    threeLetterCountryCode: 'FRA',
    twoLetterCountryCode: 'FR'
  },
  {
    continentCode: 'SA',
    continentName: 'South America',
    countryName: 'French Guiana',
    countryNumber: 254,
    threeLetterCountryCode: 'GUF',
    twoLetterCountryCode: 'GF'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'French Polynesia',
    countryNumber: 258,
    threeLetterCountryCode: 'PYF',
    twoLetterCountryCode: 'PF'
  },
  {
    continentCode: 'AN',
    continentName: 'Antarctica',
    countryName: 'French Southern Territories',
    countryNumber: 260,
    threeLetterCountryCode: 'ATF',
    twoLetterCountryCode: 'TF'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Djibouti, Republic of',
    countryNumber: 262,
    threeLetterCountryCode: 'DJI',
    twoLetterCountryCode: 'DJ'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Gabon, Gabonese Republic',
    countryNumber: 266,
    threeLetterCountryCode: 'GAB',
    twoLetterCountryCode: 'GA'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Georgia',
    countryNumber: 268,
    threeLetterCountryCode: 'GEO',
    twoLetterCountryCode: 'GE'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Georgia',
    countryNumber: 268,
    threeLetterCountryCode: 'GEO',
    twoLetterCountryCode: 'GE'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Gambia, Republic of the',
    countryNumber: 270,
    threeLetterCountryCode: 'GMB',
    twoLetterCountryCode: 'GM'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Palestinian Territory, Occupied',
    countryNumber: 275,
    threeLetterCountryCode: 'PSE',
    twoLetterCountryCode: 'PS'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Germany, Federal Republic of',
    countryNumber: 276,
    threeLetterCountryCode: 'DEU',
    twoLetterCountryCode: 'DE'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Ghana, Republic of',
    countryNumber: 288,
    threeLetterCountryCode: 'GHA',
    twoLetterCountryCode: 'GH'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Gibraltar',
    countryNumber: 292,
    threeLetterCountryCode: 'GIB',
    twoLetterCountryCode: 'GI'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Kiribati, Republic of',
    countryNumber: 296,
    threeLetterCountryCode: 'KIR',
    twoLetterCountryCode: 'KI'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Greece, Hellenic Republic',
    countryNumber: 300,
    threeLetterCountryCode: 'GRC',
    twoLetterCountryCode: 'GR'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Greenland',
    countryNumber: 304,
    threeLetterCountryCode: 'GRL',
    twoLetterCountryCode: 'GL'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Grenada',
    countryNumber: 308,
    threeLetterCountryCode: 'GRD',
    twoLetterCountryCode: 'GD'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Guadeloupe',
    countryNumber: 312,
    threeLetterCountryCode: 'GLP',
    twoLetterCountryCode: 'GP'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Guam',
    countryNumber: 316,
    threeLetterCountryCode: 'GUM',
    twoLetterCountryCode: 'GU'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Guatemala, Republic of',
    countryNumber: 320,
    threeLetterCountryCode: 'GTM',
    twoLetterCountryCode: 'GT'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Guinea, Republic of',
    countryNumber: 324,
    threeLetterCountryCode: 'GIN',
    twoLetterCountryCode: 'GN'
  },
  {
    continentCode: 'SA',
    continentName: 'South America',
    countryName: 'Guyana, Co-operative Republic of',
    countryNumber: 328,
    threeLetterCountryCode: 'GUY',
    twoLetterCountryCode: 'GY'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Haiti, Republic of',
    countryNumber: 332,
    threeLetterCountryCode: 'HTI',
    twoLetterCountryCode: 'HT'
  },
  {
    continentCode: 'AN',
    continentName: 'Antarctica',
    countryName: 'Heard Island and McDonald Islands',
    countryNumber: 334,
    threeLetterCountryCode: 'HMD',
    twoLetterCountryCode: 'HM'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Holy See (Vatican City State)',
    countryNumber: 336,
    threeLetterCountryCode: 'VAT',
    twoLetterCountryCode: 'VA'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Honduras, Republic of',
    countryNumber: 340,
    threeLetterCountryCode: 'HND',
    twoLetterCountryCode: 'HN'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Hong Kong, Special Administrative Region of China',
    countryNumber: 344,
    threeLetterCountryCode: 'HKG',
    twoLetterCountryCode: 'HK'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Hungary, Republic of',
    countryNumber: 348,
    threeLetterCountryCode: 'HUN',
    twoLetterCountryCode: 'HU'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Iceland, Republic of',
    countryNumber: 352,
    threeLetterCountryCode: 'ISL',
    twoLetterCountryCode: 'IS'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'India, Republic of',
    countryNumber: 356,
    threeLetterCountryCode: 'IND',
    twoLetterCountryCode: 'IN'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Indonesia, Republic of',
    countryNumber: 360,
    threeLetterCountryCode: 'IDN',
    twoLetterCountryCode: 'ID'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Iran, Islamic Republic of',
    countryNumber: 364,
    threeLetterCountryCode: 'IRN',
    twoLetterCountryCode: 'IR'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Iraq, Republic of',
    countryNumber: 368,
    threeLetterCountryCode: 'IRQ',
    twoLetterCountryCode: 'IQ'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Ireland',
    countryNumber: 372,
    threeLetterCountryCode: 'IRL',
    twoLetterCountryCode: 'IE'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Israel, State of',
    countryNumber: 376,
    threeLetterCountryCode: 'ISR',
    twoLetterCountryCode: 'IL'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Italy, Italian Republic',
    countryNumber: 380,
    threeLetterCountryCode: 'ITA',
    twoLetterCountryCode: 'IT'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: "Cote d'Ivoire, Republic of",
    countryNumber: 384,
    threeLetterCountryCode: 'CIV',
    twoLetterCountryCode: 'CI'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Jamaica',
    countryNumber: 388,
    threeLetterCountryCode: 'JAM',
    twoLetterCountryCode: 'JM'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Japan',
    countryNumber: 392,
    threeLetterCountryCode: 'JPN',
    twoLetterCountryCode: 'JP'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Kazakhstan, Republic of',
    countryNumber: 398,
    threeLetterCountryCode: 'KAZ',
    twoLetterCountryCode: 'KZ'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Kazakhstan, Republic of',
    countryNumber: 398,
    threeLetterCountryCode: 'KAZ',
    twoLetterCountryCode: 'KZ'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Jordan, Hashemite Kingdom of',
    countryNumber: 400,
    threeLetterCountryCode: 'JOR',
    twoLetterCountryCode: 'JO'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Kenya, Republic of',
    countryNumber: 404,
    threeLetterCountryCode: 'KEN',
    twoLetterCountryCode: 'KE'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: "Korea, Democratic People's Republic of",
    countryNumber: 408,
    threeLetterCountryCode: 'PRK',
    twoLetterCountryCode: 'KP'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Korea, Republic of',
    countryNumber: 410,
    threeLetterCountryCode: 'KOR',
    twoLetterCountryCode: 'KR'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Kuwait, State of',
    countryNumber: 414,
    threeLetterCountryCode: 'KWT',
    twoLetterCountryCode: 'KW'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Kyrgyz Republic',
    countryNumber: 417,
    threeLetterCountryCode: 'KGZ',
    twoLetterCountryCode: 'KG'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: "Lao People's Democratic Republic",
    countryNumber: 418,
    threeLetterCountryCode: 'LAO',
    twoLetterCountryCode: 'LA'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Lebanon, Lebanese Republic',
    countryNumber: 422,
    threeLetterCountryCode: 'LBN',
    twoLetterCountryCode: 'LB'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Lesotho, Kingdom of',
    countryNumber: 426,
    threeLetterCountryCode: 'LSO',
    twoLetterCountryCode: 'LS'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Latvia, Republic of',
    countryNumber: 428,
    threeLetterCountryCode: 'LVA',
    twoLetterCountryCode: 'LV'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Liberia, Republic of',
    countryNumber: 430,
    threeLetterCountryCode: 'LBR',
    twoLetterCountryCode: 'LR'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Libyan Arab Jamahiriya',
    countryNumber: 434,
    threeLetterCountryCode: 'LBY',
    twoLetterCountryCode: 'LY'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Liechtenstein, Principality of',
    countryNumber: 438,
    threeLetterCountryCode: 'LIE',
    twoLetterCountryCode: 'LI'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Lithuania, Republic of',
    countryNumber: 440,
    threeLetterCountryCode: 'LTU',
    twoLetterCountryCode: 'LT'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Luxembourg, Grand Duchy of',
    countryNumber: 442,
    threeLetterCountryCode: 'LUX',
    twoLetterCountryCode: 'LU'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Macao, Special Administrative Region of China',
    countryNumber: 446,
    threeLetterCountryCode: 'MAC',
    twoLetterCountryCode: 'MO'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Madagascar, Republic of',
    countryNumber: 450,
    threeLetterCountryCode: 'MDG',
    twoLetterCountryCode: 'MG'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Malawi, Republic of',
    countryNumber: 454,
    threeLetterCountryCode: 'MWI',
    twoLetterCountryCode: 'MW'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Malaysia',
    countryNumber: 458,
    threeLetterCountryCode: 'MYS',
    twoLetterCountryCode: 'MY'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Maldives, Republic of',
    countryNumber: 462,
    threeLetterCountryCode: 'MDV',
    twoLetterCountryCode: 'MV'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Mali, Republic of',
    countryNumber: 466,
    threeLetterCountryCode: 'MLI',
    twoLetterCountryCode: 'ML'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Malta, Republic of',
    countryNumber: 470,
    threeLetterCountryCode: 'MLT',
    twoLetterCountryCode: 'MT'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Martinique',
    countryNumber: 474,
    threeLetterCountryCode: 'MTQ',
    twoLetterCountryCode: 'MQ'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Mauritania, Islamic Republic of',
    countryNumber: 478,
    threeLetterCountryCode: 'MRT',
    twoLetterCountryCode: 'MR'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Mauritius, Republic of',
    countryNumber: 480,
    threeLetterCountryCode: 'MUS',
    twoLetterCountryCode: 'MU'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Mexico, United Mexican States',
    countryNumber: 484,
    threeLetterCountryCode: 'MEX',
    twoLetterCountryCode: 'MX'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Monaco, Principality of',
    countryNumber: 492,
    threeLetterCountryCode: 'MCO',
    twoLetterCountryCode: 'MC'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Mongolia',
    countryNumber: 496,
    threeLetterCountryCode: 'MNG',
    twoLetterCountryCode: 'MN'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Moldova, Republic of',
    countryNumber: 498,
    threeLetterCountryCode: 'MDA',
    twoLetterCountryCode: 'MD'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Montenegro, Republic of',
    countryNumber: 499,
    threeLetterCountryCode: 'MNE',
    twoLetterCountryCode: 'ME'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Montserrat',
    countryNumber: 500,
    threeLetterCountryCode: 'MSR',
    twoLetterCountryCode: 'MS'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Morocco, Kingdom of',
    countryNumber: 504,
    threeLetterCountryCode: 'MAR',
    twoLetterCountryCode: 'MA'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Mozambique, Republic of',
    countryNumber: 508,
    threeLetterCountryCode: 'MOZ',
    twoLetterCountryCode: 'MZ'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Oman, Sultanate of',
    countryNumber: 512,
    threeLetterCountryCode: 'OMN',
    twoLetterCountryCode: 'OM'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Namibia, Republic of',
    countryNumber: 516,
    threeLetterCountryCode: 'NAM',
    twoLetterCountryCode: 'NA'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Nauru, Republic of',
    countryNumber: 520,
    threeLetterCountryCode: 'NRU',
    twoLetterCountryCode: 'NR'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Nepal, State of',
    countryNumber: 524,
    threeLetterCountryCode: 'NPL',
    twoLetterCountryCode: 'NP'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Netherlands, Kingdom of the',
    countryNumber: 528,
    threeLetterCountryCode: 'NLD',
    twoLetterCountryCode: 'NL'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Netherlands Antilles',
    countryNumber: 530,
    threeLetterCountryCode: 'ANT',
    twoLetterCountryCode: 'AN'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Cura\u00e7ao',
    countryNumber: 531,
    threeLetterCountryCode: 'CUW',
    twoLetterCountryCode: 'CW'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Aruba',
    countryNumber: 533,
    threeLetterCountryCode: 'ABW',
    twoLetterCountryCode: 'AW'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Sint Maarten (Netherlands)',
    countryNumber: 534,
    threeLetterCountryCode: 'SXM',
    twoLetterCountryCode: 'SX'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Bonaire, Sint Eustatius and Saba',
    countryNumber: 535,
    threeLetterCountryCode: 'BES',
    twoLetterCountryCode: 'BQ'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'New Caledonia',
    countryNumber: 540,
    threeLetterCountryCode: 'NCL',
    twoLetterCountryCode: 'NC'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Vanuatu, Republic of',
    countryNumber: 548,
    threeLetterCountryCode: 'VUT',
    twoLetterCountryCode: 'VU'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'New Zealand',
    countryNumber: 554,
    threeLetterCountryCode: 'NZL',
    twoLetterCountryCode: 'NZ'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Nicaragua, Republic of',
    countryNumber: 558,
    threeLetterCountryCode: 'NIC',
    twoLetterCountryCode: 'NI'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Niger, Republic of',
    countryNumber: 562,
    threeLetterCountryCode: 'NER',
    twoLetterCountryCode: 'NE'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Nigeria, Federal Republic of',
    countryNumber: 566,
    threeLetterCountryCode: 'NGA',
    twoLetterCountryCode: 'NG'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Niue',
    countryNumber: 570,
    threeLetterCountryCode: 'NIU',
    twoLetterCountryCode: 'NU'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Norfolk Island',
    countryNumber: 574,
    threeLetterCountryCode: 'NFK',
    twoLetterCountryCode: 'NF'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Norway, Kingdom of',
    countryNumber: 578,
    threeLetterCountryCode: 'NOR',
    twoLetterCountryCode: 'NO'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Northern Mariana Islands, Commonwealth of the',
    countryNumber: 580,
    threeLetterCountryCode: 'MNP',
    twoLetterCountryCode: 'MP'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'United States Minor Outlying Islands',
    countryNumber: 581,
    threeLetterCountryCode: 'UMI',
    twoLetterCountryCode: 'UM'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'United States Minor Outlying Islands',
    countryNumber: 581,
    threeLetterCountryCode: 'UMI',
    twoLetterCountryCode: 'UM'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Micronesia, Federated States of',
    countryNumber: 583,
    threeLetterCountryCode: 'FSM',
    twoLetterCountryCode: 'FM'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Marshall Islands, Republic of the',
    countryNumber: 584,
    threeLetterCountryCode: 'MHL',
    twoLetterCountryCode: 'MH'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Palau, Republic of',
    countryNumber: 585,
    threeLetterCountryCode: 'PLW',
    twoLetterCountryCode: 'PW'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Pakistan, Islamic Republic of',
    countryNumber: 586,
    threeLetterCountryCode: 'PAK',
    twoLetterCountryCode: 'PK'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Panama, Republic of',
    countryNumber: 591,
    threeLetterCountryCode: 'PAN',
    twoLetterCountryCode: 'PA'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Papua New Guinea, Independent State of',
    countryNumber: 598,
    threeLetterCountryCode: 'PNG',
    twoLetterCountryCode: 'PG'
  },
  {
    continentCode: 'SA',
    continentName: 'South America',
    countryName: 'Paraguay, Republic of',
    countryNumber: 600,
    threeLetterCountryCode: 'PRY',
    twoLetterCountryCode: 'PY'
  },
  {
    continentCode: 'SA',
    continentName: 'South America',
    countryName: 'Peru, Republic of',
    countryNumber: 604,
    threeLetterCountryCode: 'PER',
    twoLetterCountryCode: 'PE'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Philippines, Republic of the',
    countryNumber: 608,
    threeLetterCountryCode: 'PHL',
    twoLetterCountryCode: 'PH'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Pitcairn Islands',
    countryNumber: 612,
    threeLetterCountryCode: 'PCN',
    twoLetterCountryCode: 'PN'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Poland, Republic of',
    countryNumber: 616,
    threeLetterCountryCode: 'POL',
    twoLetterCountryCode: 'PL'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Portugal, Portuguese Republic',
    countryNumber: 620,
    threeLetterCountryCode: 'PRT',
    twoLetterCountryCode: 'PT'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Guinea-Bissau, Republic of',
    countryNumber: 624,
    threeLetterCountryCode: 'GNB',
    twoLetterCountryCode: 'GW'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Timor-Leste, Democratic Republic of',
    countryNumber: 626,
    threeLetterCountryCode: 'TLS',
    twoLetterCountryCode: 'TL'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Puerto Rico, Commonwealth of',
    countryNumber: 630,
    threeLetterCountryCode: 'PRI',
    twoLetterCountryCode: 'PR'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Qatar, State of',
    countryNumber: 634,
    threeLetterCountryCode: 'QAT',
    twoLetterCountryCode: 'QA'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Reunion',
    countryNumber: 638,
    threeLetterCountryCode: 'REU',
    twoLetterCountryCode: 'RE'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Romania',
    countryNumber: 642,
    threeLetterCountryCode: 'ROU',
    twoLetterCountryCode: 'RO'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Russian Federation',
    countryNumber: 643,
    threeLetterCountryCode: 'RUS',
    twoLetterCountryCode: 'RU'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Russian Federation',
    countryNumber: 643,
    threeLetterCountryCode: 'RUS',
    twoLetterCountryCode: 'RU'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Rwanda, Republic of',
    countryNumber: 646,
    threeLetterCountryCode: 'RWA',
    twoLetterCountryCode: 'RW'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Saint Barthelemy',
    countryNumber: 652,
    threeLetterCountryCode: 'BLM',
    twoLetterCountryCode: 'BL'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Saint Helena',
    countryNumber: 654,
    threeLetterCountryCode: 'SHN',
    twoLetterCountryCode: 'SH'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Saint Kitts and Nevis, Federation of',
    countryNumber: 659,
    threeLetterCountryCode: 'KNA',
    twoLetterCountryCode: 'KN'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Anguilla',
    countryNumber: 660,
    threeLetterCountryCode: 'AIA',
    twoLetterCountryCode: 'AI'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Saint Lucia',
    countryNumber: 662,
    threeLetterCountryCode: 'LCA',
    twoLetterCountryCode: 'LC'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Saint Martin',
    countryNumber: 663,
    threeLetterCountryCode: 'MAF',
    twoLetterCountryCode: 'MF'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Saint Pierre and Miquelon',
    countryNumber: 666,
    threeLetterCountryCode: 'SPM',
    twoLetterCountryCode: 'PM'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Saint Vincent and the Grenadines',
    countryNumber: 670,
    threeLetterCountryCode: 'VCT',
    twoLetterCountryCode: 'VC'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'San Marino, Republic of',
    countryNumber: 674,
    threeLetterCountryCode: 'SMR',
    twoLetterCountryCode: 'SM'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Sao Tome and Principe, Democratic Republic of',
    countryNumber: 678,
    threeLetterCountryCode: 'STP',
    twoLetterCountryCode: 'ST'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Saudi Arabia, Kingdom of',
    countryNumber: 682,
    threeLetterCountryCode: 'SAU',
    twoLetterCountryCode: 'SA'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Senegal, Republic of',
    countryNumber: 686,
    threeLetterCountryCode: 'SEN',
    twoLetterCountryCode: 'SN'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Serbia, Republic of',
    countryNumber: 688,
    threeLetterCountryCode: 'SRB',
    twoLetterCountryCode: 'RS'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Seychelles, Republic of',
    countryNumber: 690,
    threeLetterCountryCode: 'SYC',
    twoLetterCountryCode: 'SC'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Sierra Leone, Republic of',
    countryNumber: 694,
    threeLetterCountryCode: 'SLE',
    twoLetterCountryCode: 'SL'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Singapore, Republic of',
    countryNumber: 702,
    threeLetterCountryCode: 'SGP',
    twoLetterCountryCode: 'SG'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Slovakia (Slovak Republic)',
    countryNumber: 703,
    threeLetterCountryCode: 'SVK',
    twoLetterCountryCode: 'SK'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Vietnam, Socialist Republic of',
    countryNumber: 704,
    threeLetterCountryCode: 'VNM',
    twoLetterCountryCode: 'VN'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Slovenia, Republic of',
    countryNumber: 705,
    threeLetterCountryCode: 'SVN',
    twoLetterCountryCode: 'SI'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Somalia, Somali Republic',
    countryNumber: 706,
    threeLetterCountryCode: 'SOM',
    twoLetterCountryCode: 'SO'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'South Africa, Republic of',
    countryNumber: 710,
    threeLetterCountryCode: 'ZAF',
    twoLetterCountryCode: 'ZA'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Zimbabwe, Republic of',
    countryNumber: 716,
    threeLetterCountryCode: 'ZWE',
    twoLetterCountryCode: 'ZW'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Spain, Kingdom of',
    countryNumber: 724,
    threeLetterCountryCode: 'ESP',
    twoLetterCountryCode: 'ES'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'South Sudan',
    countryNumber: 728,
    threeLetterCountryCode: 'SSD',
    twoLetterCountryCode: 'SS'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Western Sahara',
    countryNumber: 732,
    threeLetterCountryCode: 'ESH',
    twoLetterCountryCode: 'EH'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Sudan, Republic of',
    countryNumber: 736,
    threeLetterCountryCode: 'SDN',
    twoLetterCountryCode: 'SD'
  },
  {
    continentCode: 'SA',
    continentName: 'South America',
    countryName: 'Suriname, Republic of',
    countryNumber: 740,
    threeLetterCountryCode: 'SUR',
    twoLetterCountryCode: 'SR'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Svalbard & Jan Mayen Islands',
    countryNumber: 744,
    threeLetterCountryCode: 'SJM',
    twoLetterCountryCode: 'SJ'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Swaziland, Kingdom of',
    countryNumber: 748,
    threeLetterCountryCode: 'SWZ',
    twoLetterCountryCode: 'SZ'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Sweden, Kingdom of',
    countryNumber: 752,
    threeLetterCountryCode: 'SWE',
    twoLetterCountryCode: 'SE'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Switzerland, Swiss Confederation',
    countryNumber: 756,
    threeLetterCountryCode: 'CHE',
    twoLetterCountryCode: 'CH'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Syrian Arab Republic',
    countryNumber: 760,
    threeLetterCountryCode: 'SYR',
    twoLetterCountryCode: 'SY'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Tajikistan, Republic of',
    countryNumber: 762,
    threeLetterCountryCode: 'TJK',
    twoLetterCountryCode: 'TJ'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Thailand, Kingdom of',
    countryNumber: 764,
    threeLetterCountryCode: 'THA',
    twoLetterCountryCode: 'TH'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Togo, Togolese Republic',
    countryNumber: 768,
    threeLetterCountryCode: 'TGO',
    twoLetterCountryCode: 'TG'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Tokelau',
    countryNumber: 772,
    threeLetterCountryCode: 'TKL',
    twoLetterCountryCode: 'TK'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Tonga, Kingdom of',
    countryNumber: 776,
    threeLetterCountryCode: 'TON',
    twoLetterCountryCode: 'TO'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Trinidad and Tobago, Republic of',
    countryNumber: 780,
    threeLetterCountryCode: 'TTO',
    twoLetterCountryCode: 'TT'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'United Arab Emirates',
    countryNumber: 784,
    threeLetterCountryCode: 'ARE',
    twoLetterCountryCode: 'AE'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Tunisia, Tunisian Republic',
    countryNumber: 788,
    threeLetterCountryCode: 'TUN',
    twoLetterCountryCode: 'TN'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Turkey, Republic of',
    countryNumber: 792,
    threeLetterCountryCode: 'TUR',
    twoLetterCountryCode: 'TR'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Turkey, Republic of',
    countryNumber: 792,
    threeLetterCountryCode: 'TUR',
    twoLetterCountryCode: 'TR'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Turkmenistan',
    countryNumber: 795,
    threeLetterCountryCode: 'TKM',
    twoLetterCountryCode: 'TM'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'Turks and Caicos Islands',
    countryNumber: 796,
    threeLetterCountryCode: 'TCA',
    twoLetterCountryCode: 'TC'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Tuvalu',
    countryNumber: 798,
    threeLetterCountryCode: 'TUV',
    twoLetterCountryCode: 'TV'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Uganda, Republic of',
    countryNumber: 800,
    threeLetterCountryCode: 'UGA',
    twoLetterCountryCode: 'UG'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Ukraine',
    countryNumber: 804,
    threeLetterCountryCode: 'UKR',
    twoLetterCountryCode: 'UA'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Macedonia, The Former Yugoslav Republic of',
    countryNumber: 807,
    threeLetterCountryCode: 'MKD',
    twoLetterCountryCode: 'MK'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Egypt, Arab Republic of',
    countryNumber: 818,
    threeLetterCountryCode: 'EGY',
    twoLetterCountryCode: 'EG'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'United Kingdom of Great Britain & Northern Ireland',
    countryNumber: 826,
    threeLetterCountryCode: 'GBR',
    twoLetterCountryCode: 'GB'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Guernsey, Bailiwick of',
    countryNumber: 831,
    threeLetterCountryCode: 'GGY',
    twoLetterCountryCode: 'GG'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Jersey, Bailiwick of',
    countryNumber: 832,
    threeLetterCountryCode: 'JEY',
    twoLetterCountryCode: 'JE'
  },
  {
    continentCode: 'EU',
    continentName: 'Europe',
    countryName: 'Isle of Man',
    countryNumber: 833,
    threeLetterCountryCode: 'IMN',
    twoLetterCountryCode: 'IM'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Tanzania, United Republic of',
    countryNumber: 834,
    threeLetterCountryCode: 'TZA',
    twoLetterCountryCode: 'TZ'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'United States of America',
    countryNumber: 840,
    threeLetterCountryCode: 'USA',
    twoLetterCountryCode: 'US'
  },
  {
    continentCode: 'NA',
    continentName: 'North America',
    countryName: 'United States Virgin Islands',
    countryNumber: 850,
    threeLetterCountryCode: 'VIR',
    twoLetterCountryCode: 'VI'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Burkina Faso',
    countryNumber: 854,
    threeLetterCountryCode: 'BFA',
    twoLetterCountryCode: 'BF'
  },
  {
    continentCode: 'SA',
    continentName: 'South America',
    countryName: 'Uruguay, Eastern Republic of',
    countryNumber: 858,
    threeLetterCountryCode: 'URY',
    twoLetterCountryCode: 'UY'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Uzbekistan, Republic of',
    countryNumber: 860,
    threeLetterCountryCode: 'UZB',
    twoLetterCountryCode: 'UZ'
  },
  {
    continentCode: 'SA',
    continentName: 'South America',
    countryName: 'Venezuela, Bolivarian Republic of',
    countryNumber: 862,
    threeLetterCountryCode: 'VEN',
    twoLetterCountryCode: 'VE'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Wallis and Futuna',
    countryNumber: 876,
    threeLetterCountryCode: 'WLF',
    twoLetterCountryCode: 'WF'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Samoa, Independent State of',
    countryNumber: 882,
    threeLetterCountryCode: 'WSM',
    twoLetterCountryCode: 'WS'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Yemen',
    countryNumber: 887,
    threeLetterCountryCode: 'YEM',
    twoLetterCountryCode: 'YE'
  },
  {
    continentCode: 'AF',
    continentName: 'Africa',
    countryName: 'Zambia, Republic of',
    countryNumber: 894,
    threeLetterCountryCode: 'ZMB',
    twoLetterCountryCode: 'ZM'
  },
  {
    continentCode: 'OC',
    continentName: 'Oceania',
    countryName: 'Disputed Territory',
    countryNumber: null,
    threeLetterCountryCode: null,
    twoLetterCountryCode: 'XX'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Iraq-Saudi Arabia Neutral Zone',
    countryNumber: null,
    threeLetterCountryCode: null,
    twoLetterCountryCode: 'XE'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'United Nations Neutral Zone',
    countryNumber: null,
    threeLetterCountryCode: null,
    twoLetterCountryCode: 'XD'
  },
  {
    continentCode: 'AS',
    continentName: 'Asia',
    countryName: 'Spratly Islands',
    countryNumber: null,
    threeLetterCountryCode: null,
    twoLetterCountryCode: 'XS'
  }
];
