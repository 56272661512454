import React from 'react';
import { Link, NavLink, RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useAppContext } from 'app-context';
import { Auth } from 'aws-amplify';

function NavBar({ history }: RouteComponentProps) {
  const { isAuthenticated, userHasAuthenticated } = useAppContext();

  return (
    <nav className="navbar navbar-expand-sm bg-dark navbar-dark mb-2">
      <Link to="/" className="navbar-brand">
        <img src="/favicon.ico" className="navbar-brand-logo" alt="Evil Genius Labs Logo" />
        Admin
      </Link>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav mr-auto">
          {isAuthenticated && (
            <>
              <li className="nav-item">
                <NavLink className="nav-link" to="/orders">
                  Orders
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/items">
                  Items
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink className="nav-link" to="/pings">
                  Pings
                </NavLink>
              </li>
            </>
          )}
        </ul>
        <ul className="navbar-nav">
          <li className="nav-item">
            {isAuthenticated && (
              <button
                className="btn btn-outline-secondary"
                style={{ cursor: 'pointer' }}
                onClick={async () => {
                  await Auth.signOut();
                  userHasAuthenticated(false);
                }}
              >
                Logout
              </button>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default withRouter(NavBar);
