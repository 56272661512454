import { useContext, createContext } from 'react';

export const AppContext = createContext(
  {
     isAuthenticated: false,
     userHasAuthenticated: null as any
  });

export function useAppContext() {
  return useContext(AppContext);
}
