import BreadcrumbBar from 'components/BreadcrumbBar';
import ErrorAlert from 'components/ErrorAlert';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import Moment from 'react-moment';
import { firstBy } from 'thenby';
import { groupBy } from 'utils/groupBy';
import { PINGS } from './queries';
// import pingsFixture from './pings.json';

function DevicesTable() {
  useEffect(() => {
    document.title = 'Devices | Evil Genius Labs';
  }, []);

  const { loading, error, data } = useQuery(PINGS);
  // const { loading = false, error = null, data = pingsFixture.data } = {};

  const breadcrumbBar = (
    <BreadcrumbBar
      items={[
        { text: 'Admin', to: '/' },
        { text: 'Pings', to: '/pings' },
        { text: 'Ping Map', to: '/pings/map' },
        { text: 'Devices' }
      ]}
    />
  );

  const spinner = loading && (
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );

  const errorAlert = error && <ErrorAlert error={error} />;

  const { admin } = data || {};
  const { pings } = admin || {};
  const { items } = pings || [];

  // show only the latest ping from each unique device mac address
  const macAddresses = groupBy(items, 'macAddress');

  const macAddressRows =
    macAddresses &&
    Object.entries(macAddresses).map((group: any) => {
      const pings = group?.[1];
      pings?.sort((a, b) => (a?.updatedAt === b?.updatedAt ? 0 : a?.updatedAt < b?.updatedAt ? 1 : -1));
      return pings?.[0];
    });

  // sort the unique devices descending by continent, country, region, city, ip, then map to rows
  const rows = macAddressRows
    ?.map((ping, i) => {
      const { sourceIp, deviceName, localIp, updatedAt, createdAt, millis, macAddress, sourceIpData } = ping;
      const {
        // zip,
        city,
        // region_code,
        region_name,
        // country_code,
        country_name,
        // continent_code,
        continent_name,
        location
      } = sourceIpData;
      return {
        sourceIp,
        deviceName,
        localIp,
        updatedAt,
        createdAt,
        millis,
        macAddress,
        sourceIpData,
        // zip,
        city,
        // region_code,
        region_name,
        // country_code,
        country_name,
        // continent_code,
        continent_name,
        location
      };
    })
    .sort(firstBy('continent_name').thenBy('country_name').thenBy('region_name').thenBy('city').thenBy('sourceIp'));

  const count = rows?.length;
  const continents = groupBy(rows, 'continent_name');
  const continentsCount = continents && Object.entries(continents).length;
  const countriesGroup = groupBy(rows, 'country_name');
  const countriesCount = countriesGroup && Object.entries(countriesGroup).length;
  const regionsGroup = groupBy(rows, 'region_name');
  const regionsCount = regionsGroup && Object.entries(regionsGroup).length;
  const citiesGroup = groupBy(rows, 'city');
  const citiesCount = citiesGroup && Object.entries(citiesGroup).length;
  const ipsGroup = groupBy(rows, 'sourceIp');
  const ipsCount = ipsGroup && Object.entries(ipsGroup).length;
  const deviceNamesGroup = groupBy(rows, 'deviceName');
  const deviceNamesCount = deviceNamesGroup && Object.entries(deviceNamesGroup).length;
  const localIpsGroup = groupBy(rows, 'localIp');
  const localIpsCount = localIpsGroup && Object.entries(localIpsGroup).length;

  return (
    <>
      {breadcrumbBar}
      {errorAlert}
      {rows && rows.length && (
        <div className="table-responsive" style={{ marginTop: '16px' }}>
          <table className="table table-striped table-hover table-sm">
            <thead>
              <tr>
                <th scope="col" />
                <th scope="col">Continent</th>
                <th scope="col">Country</th>
                <th scope="col">Region</th>
                <th scope="col">City</th>
                <th scope="col">Public IP</th>
                <th scope="col">Device Name</th>
                <th scope="col">Local IP</th>
                <th scope="col">Last Seen</th>
                <th scope="col">First Seen</th>
                <th scope="col">Uptime</th>
                <th scope="col">MAC Address</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{count}</td>
                <td>{continentsCount}</td>
                <td>{countriesCount}</td>
                <td>{regionsCount}</td>
                <td>{citiesCount}</td>
                <td>{ipsCount}</td>
                <td>{deviceNamesCount}</td>
                <td>{localIpsCount}</td>
                <td />
                <td />
                <td />
                <td>{count}</td>
              </tr>
              {rows.map((ping, i) => {
                const {
                  sourceIp,
                  deviceName,
                  localIp,
                  updatedAt,
                  createdAt,
                  millis,
                  macAddress,
                  // zip,
                  city,
                  // region_code,
                  region_name,
                  // country_code,
                  country_name,
                  // continent_code,
                  continent_name,
                  location
                } = ping;
                const { country_flag_emoji } = location;
                const duration = moment.duration(parseInt(millis));
                const humanDuration = duration.humanize();
                const uptimeSeconds = duration.as('seconds').toLocaleString();

                return (
                  <tr key={i}>
                    <td>
                      <span title={`${city}, ${region_name}, ${country_name}, ${continent_name}`}>
                        {country_flag_emoji}
                      </span>
                    </td>
                    <td>{continent_name}</td>
                    <td>{country_name}</td>
                    <td>{region_name}</td>
                    <td>{city}</td>
                    <td>{sourceIp}</td>
                    <td>{deviceName}</td>
                    <td>{localIp}</td>
                    <td>
                      <Moment date={updatedAt} fromNow withTitle titleFormat="LLLL" />
                    </td>
                    <td>
                      <Moment date={createdAt} fromNow withTitle titleFormat="LLLL" />
                    </td>
                    <td>
                      <span title={`${uptimeSeconds} seconds`}>{humanDuration}</span>
                    </td>
                    <td>{macAddress}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {spinner}
    </>
  );
}

export default DevicesTable;
