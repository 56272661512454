import { Formik } from 'formik';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Modal, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { countries } from 'utils/countries';
import { currency } from 'utils/format';
import { groupBy } from 'utils/groupBy';
import BreadcrumbBar from '../BreadcrumbBar';
import ErrorAlert from '../ErrorAlert';
import LocaleDate from '../LocaleDate';
import { GEOCODE_MUTATION, IMPORT_TINDIE_ORDERS_MUTATION, ORDERS_QUERY, TRACK_MUTATION } from './queries';

function OrdersTable() {
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [isTrackingOrders, setIsTrackingOrders] = useState<boolean>();
  const [trackingOrderId, setTrackingOrderId] = useState<any>();
  const [search, setSearch] = useState<string>();

  const [importTindieOrders, { loading: isImportingTindieOrders }] = useMutation(IMPORT_TINDIE_ORDERS_MUTATION, {
    onCompleted: (data) => {
      openUnshippedOrders(data?.admin?.importTindieOrders?.items);
    }
  });

  const [track, { loading: isTracking }] = useMutation(TRACK_MUTATION);

  const [geocode, { loading: isGeocoding }] = useMutation(GEOCODE_MUTATION);

  const { loading, error, data, fetchMore } = useQuery(ORDERS_QUERY, {
    variables: { limit: 20, lastEvaluatedKey: undefined }
  });
  const { items, lastEvaluatedKey } = data?.admin?.orders || {};

  const orders = items?.filter((order) => {
    if (!search) return true;

    const s = search.toLowerCase();

    return (
      order.continent_name?.toLowerCase().includes(s) ||
      order.continent_code?.toLowerCase().includes(s) ||
      order.shipping_country?.toLowerCase().includes(s) ||
      order.shipping_country_code?.toLowerCase().includes(s) ||
      order.shipping_name?.toLowerCase().includes(s) ||
      order.shipping_email?.toLowerCase().includes(s) ||
      order.shipping_state?.toLowerCase().includes(s) ||
      order.items.some((item) => item.product?.toLowerCase().includes(s)) ||
      order.items.some((item) => item.sku?.toLowerCase().includes(s))
    );
  });

  orders?.forEach((order) => {
    const country = countries.find((country) => country.twoLetterCountryCode === order.shipping_country_code);
    order.continent_name = country?.continentName;
    order.continent_code = country?.continentCode;
  });

  // console.log({ orders });

  const count = orders?.length;
  const undeliveredCount = orders?.filter((o) => !o.delivered).length;
  const continents = groupBy(orders, 'continent_name');
  const continentsCount = continents && Object.entries(continents).length;
  const countriesGroup = groupBy(orders, 'shipping_country');
  const countriesCount = countriesGroup && Object.entries(countriesGroup).length;
  const names = groupBy(orders, 'shipping_name');
  const namesCount = names && Object.entries(names).length;
  const emails = groupBy(orders, 'email');
  const emailsCount = emails && Object.entries(emails).length;
  const cities = groupBy(orders, 'shipping_city');
  const citiesCount = cities && Object.entries(cities).length;
  const states = groupBy(orders, 'shipping_state');
  const statesCount = states && Object.entries(states).length;
  const itemsCount = orders?.reduce((total, current) => total + current.items.length, 0);
  const total = orders?.reduce((total, current) => total + current.total_subtotal, 0);
  const profit = orders?.reduce((total, current) => total + current.profit, 0);

  const totalContinents = Object.entries(groupBy(countries, 'continentName')).length;
  const continentsPercent = ((continentsCount / totalContinents) * 100).toFixed(0);

  const totalCountries = countries.length;
  const countriesPercent = ((countriesCount / countries.length) * 100).toFixed(0);

  const usStates = groupBy(
    orders?.filter((o) => o.shipping_country_code === 'US'),
    'shipping_state'
  );
  const usStatesCount = usStates && Object.entries(usStates).length;

  const trackOrders = async () => {
    setIsTrackingOrders(true);

    // console.log('tracking orders...');

    for (const order of orders.filter((o) => !o.delivered && o.tracking_code)) {
      setTrackingOrderId(order.id);
      // console.log({ trackingOrderId: order.id });
      await track({ variables: { id: order.id } });
    }

    // console.log('finished tracking orders');

    setTrackingOrderId(null);
    setIsTrackingOrders(false);
  };

  const openUnshippedOrders = (orders) => {
    for (const order of orders.filter((o) => !o.shipped)) {
      window.open(`https://www.tindie.com/orders/${order.number}`, '_blank');
      window.open(`https://www.tindie.com/orders/print/${order.number}`, '_blank');
      window.open(`/orders/${order.number}`, '_blank');
    }
    window.open('https://ship.pirateship.com/ship/single');
  };

  const breadcrumbBar = <BreadcrumbBar items={[{ text: 'Admin', to: '/' }, { text: 'Orders' }]} />;

  const spinner = loading && (
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );

  const table = orders && (
    <>
      <Formik
        initialValues={{ search: search }}
        onSubmit={(values, actions) => {
          try {
            setSearch(values.search);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, handleChange, isSubmitting, handleBlur }) => (
          <form className="form-inline mb-1" onSubmit={handleSubmit}>
            <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
              <div className="input-group mr-1">
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  name="search"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="input-group-append">
                  <button className="btn btn-outline-success" type="submit" disabled={loading || isSubmitting}>
                    <i className="fas fa-search" title="Search" aria-label="Search" />
                  </button>
                </div>
              </div>
            </div>
            <div className="btn-group mr-2" role="group" aria-label="First group">
              <button
                type="button"
                className="btn btn-outline-secondary"
                disabled={isTrackingOrders}
                onClick={async () => await trackOrders()}
              >
                <i className="fas fa-sync-alt" title="Track" aria-label="Track all undelivered orders" /> All
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary"
                disabled={isImportingTindieOrders}
                onClick={async () => await importTindieOrders({ variables: { unshipped: true } })}
              >
                <i className="fas fa-cloud-download-alt" title="Import Unshipped" aria-label="Import Unshipped" />{' '}
                Unshipped
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary"
                disabled={isImportingTindieOrders}
                onClick={() => openUnshippedOrders(orders)}
              >
                <i className="fas fa-external-link-alt" title="Open Unshipped" aria-label="Open Unshipped" /> Unshipped
              </button>
              {/* <button
                type="button"
                className="btn btn-outline-secondary"
                disabled={isImportingTindieOrders}
                onClick={async () => await importTindieOrders({ variables: { unshipped: false } })}
              >
                <i className="fas fa-cloud-download-alt" title="Import All" aria-label="Import All" /> All
              </button> */}
              <div className="btn-group mr-2" role="group">
                <Link className="btn btn-outline-secondary" to="/orders/map">
                  <i className="fas fa-map-marked-alt" title="Order Map" aria-label="Order Map" /> Map
                </Link>
              </div>
              <div className="btn-group mr-2" role="group">
                <Link className="btn btn-outline-secondary" to="/orders/stats">
                  <i className="fas fa-chart-line" title="Order Stats" aria-label="Order Stats" /> Stats
                </Link>
              </div>
              <button
                type="button"
                className="btn btn-outline-secondary"
                disabled={loading || !lastEvaluatedKey}
                onClick={async () =>
                  await fetchMore({
                    variables: { limit: 20, lastEvaluatedKey: lastEvaluatedKey },
                    updateQuery: (prev: any, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      console.log({ prev, fetchMoreResult });
                      return Object.assign({}, prev, {
                        ...prev,
                        admin: {
                          ...prev.admin,
                          orders: {
                            ...prev.admin.orders,
                            items: [...prev.admin.orders.items, ...fetchMoreResult.admin.orders.items],
                            lastEvaluatedKey: fetchMoreResult.admin.orders.lastEvaluatedKey
                          }
                        }
                      });
                    }
                  })
                }
              >
                <i className="fas fa-angle-right" title="Load More Orders" aria-label="Load More Orders" /> More
              </button>
            </div>
          </form>
        )}
      </Formik>

      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>Number</th>
            <th>Date</th>
            <th>Status</th>
            <th>Continent</th>
            <th>Country</th>
            <th>Name</th>
            <th>Email</th>
            <th>City</th>
            <th>State</th>
            <th>Items</th>
            <th>Total</th>
            <th>Profit</th>
          </tr>
          <tr>
            <th>{count.toLocaleString()}</th>
            <th></th>
            <th>{undeliveredCount.toLocaleString()}</th>
            <th
              title={`${continentsCount.toLocaleString()}/${totalContinents.toLocaleString()} (${continentsPercent}%)`}
            >
              {continentsCount.toLocaleString()}
            </th>
            <th title={`${countriesCount.toLocaleString()}/${totalCountries.toLocaleString()} (${countriesPercent}%)`}>
              {countriesCount.toLocaleString()}
            </th>
            <th>{namesCount.toLocaleString()}</th>
            <th>{emailsCount.toLocaleString()}</th>
            <th>{citiesCount.toLocaleString()}</th>
            <th title={`${usStatesCount.toLocaleString()} US States`}>{statesCount.toLocaleString()}</th>
            <th>{itemsCount.toLocaleString()}</th>
            <th>{currency.format(total)}</th>
            <th>{currency.format(profit)}</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => {
            if (order.isTotalRow) {
              return (
                <tr key={index}>
                  <th>{order.number.toLocaleString()}</th>
                  <th>
                    <LocaleDate date={order.date} />
                  </th>
                  <th>{order.undelivered?.toLocaleString()}</th>
                  <th>{order.continents?.toLocaleString()}</th>
                  <th>{order.countries?.toLocaleString()}</th>
                  <th>{order.names?.toLocaleString()}</th>
                  <th>{order.emails?.toLocaleString()}</th>
                  <th>{order.cities?.toLocaleString()}</th>
                  <th>{order.states?.toLocaleString()}</th>
                  <th>{order.items?.toLocaleString()}</th>
                  <th>{currency.format(order.total)}</th>
                  <th>{currency.format(order.profit)}</th>
                </tr>
              );
            }

            let status = 'Unshipped';
            let statusClassName = 'btn-danger';
            if (order?.shipped) {
              status = 'Shipped';
              statusClassName = 'btn-warning';
            }
            if (order?.delivered) {
              status = 'Delivered';
              statusClassName = 'btn-success';
            }

            const trackingUrl = order.tracking_code?.startsWith('AHOY')
              ? `https://a1.asendiausa.com/tracking/?trackingkey=A6320AE0-4D36-45E7-BF95-893D6DE3F2D1&trackingnumber=${order.tracking_code}`
              : order.tracking_code?.startsWith('1Z')
              ? `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${order.tracking_code}&requester=ST/trackdetails`
              : `https://tools.usps.com/go/TrackConfirmAction?tLabels=${order.tracking_code}`;

            return (
              <tr key={index}>
                <td>
                  <a
                    href={`https://www.tindie.com/orders/${order.number}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Open Tindie order in new tab"
                  >
                    {order.number}
                  </a>
                </td>
                <td>
                  <Link to={`/orders/${order.number}`}>
                    <LocaleDate date={order.date} />
                  </Link>
                </td>
                <td>
                  <div className="btn-group" role="group">
                    <button
                      className={`btn btn-xs ${statusClassName}`}
                      onClick={() => setSelectedOrder(order)}
                      title={order?.tracking_status}
                    >
                      {order?.latest_event_date ? <LocaleDate date={order.latest_event_date} /> : status}
                    </button>
                    <a
                      className="btn btn-xs btn-outline-secondary"
                      role="button"
                      href={trackingUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Open Tracking Details"
                    >
                      <i className="fas fa-external-link-alt fa-xs" />
                    </a>
                    {!order?.delivered && (
                      <button
                        className="btn btn-xs btn-outline-secondary"
                        disabled={isTracking}
                        onClick={async () => {
                          setTrackingOrderId(order.id);
                          await track({ variables: { id: order.id } });
                          setTrackingOrderId(null);
                        }}
                      >
                        {trackingOrderId === order.id ? (
                          <i className="fas fa-sync-alt fa-xs fa-spin" />
                        ) : (
                          <i className="fas fa-sync-alt fa-xs" />
                        )}
                      </button>
                    )}
                  </div>
                </td>
                <td title={order.continent_name}>{order.continent_code}</td>
                <td>
                  <span title={order.shipping_country}>
                    <span title={order.shipping_country}>{order.shipping_country_code}</span>
                    {(!order.latitude || !order.longitude) && (
                      <button
                        className="btn btn-xs btn-outline-secondary"
                        disabled={isGeocoding}
                        onClick={async () =>
                          await geocode({
                            variables: { id: order.id }
                          })
                        }
                      >
                        <i className="fas fa-sync-alt fa-xs" />
                      </button>
                    )}
                  </span>
                </td>
                <td>{order.shipping_name}</td>
                <td>{order.email}</td>
                <td>{order.shipping_city}</td>
                <td>{order.shipping_state}</td>
                <td title={order.items?.map((item) => item.product).join(', ')}>
                  {order.items?.reduce((total, current) => current.quantity + total, 0)?.toLocaleString()}
                </td>
                <td>{currency.format(order.total_subtotal)}</td>
                <td>{currency.format(order.profit)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );

  const errorAlert = error && <ErrorAlert error={error} />;

  const orderModal = selectedOrder && (
    <Modal show={selectedOrder} onHide={() => setSelectedOrder(null)}>
      <Modal.Header closeButton>
        <Modal.Title>Order #{selectedOrder.number}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          {selectedOrder.tracking_status && (
            <li>
              <b>{selectedOrder.tracking_status}</b>
            </li>
          )}
          {selectedOrder?.tracking_history?.split('\n').map((line, index) => (
            <li key={`line${index}`}>{line}</li>
          ))}
        </ul>
      </Modal.Body>
    </Modal>
  );

  return (
    <>
      {breadcrumbBar}
      {errorAlert}
      {orderModal}
      {table}
      {spinner}
    </>
  );
}

export default OrdersTable;
