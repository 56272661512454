import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OrderDetail from './OrderDetail';
import OrderMap from './OrderMap';
import OrdersTable from './OrdersTable';
import OrderStats from './OrderStats';

function Orders() {
  return (
    <Switch>
      <Route path="/orders/map" component={OrderMap} />
      <Route path="/orders/stats" component={OrderStats} />
      <Route path="/orders/all" component={OrdersTable} />
      <Route path="/orders/:id" component={OrderDetail} />
      <Route path="/orders" component={OrdersTable} />
    </Switch>
  );
}

export default Orders;
