import gql from 'graphql-tag';

export const IMPORT_TINDIE_ORDERS_MUTATION = gql`
  mutation importTindieOrders($unshipped: Boolean, $pageSize: Int, $pageNumber: Int) {
    admin {
      importTindieOrders(unshipped: $unshipped, pageSize: $pageSize, pageNumber: $pageNumber) {
        items {
          id
          # company_title
          date
          date_shipped
          # discount_code
          email
          # message
          number
          # payment
          # phone
          # refunded
          shipped
          shipping_city
          shipping_country
          shipping_country_code
          shipping_instructions
          shipping_name
          # shipping_postcode
          # shipping_service
          shipping_state
          # shipping_street
          # total
          # total_ccfee
          # total_discount
          # total_kickback
          # total_seller
          # total_shipping
          total_subtotal
          # total_tindiefee
          tracking_code
          tracking_url

          delivered
          tracking_history
          tracking_status
          latitude
          longitude
          cost
          profit
          # shipping_cost

          items {
            # model_number
            # options
            # pre_order
            # price_total
            # price_unit
            # product
            quantity
            # sku
            # status
          }
        }
        pageInfo {
          size
          current
          sortBy
          sortDirection
          total
        }
      }
    }
  }
`;

export const ORDERS_QUERY = gql`
  query orders($lastEvaluatedKey: String, $limit: Int) {
    admin {
      orders(lastEvaluatedKey: $lastEvaluatedKey, limit: $limit) {
        items {
          id
          date
          date_shipped
          email
          number
          shipped
          shipping_city
          shipping_country
          shipping_country_code
          shipping_instructions
          shipping_name
          shipping_state
          total_subtotal
          tracking_code
          tracking_url

          delivered
          tracking_history
          tracking_status
          latest_event_date
          latitude
          longitude
          cost
          profit
          total_tindiefee
          shipping_cost

          items {
            quantity
            product
            sku
            model_number
            options
            price_total
            price_unit
          }
        }
        lastEvaluatedKey
      }
    }
  }
`;

export const TRACK_MUTATION = gql`
  mutation track($id: Int!) {
    admin {
      track(id: $id) {
        id
        date
        date_shipped
        email
        number
        shipped
        shipping_city
        shipping_country
        shipping_country_code
        shipping_instructions
        shipping_name
        shipping_state
        total_subtotal
        tracking_code
        tracking_url

        delivered
        tracking_history
        tracking_status
        latest_event_date
        latitude
        longitude
        cost
        profit

        items {
          quantity
        }
      }
    }
  }
`;

export const GEOCODE_MUTATION = gql`
  mutation geocode($id: Int!) {
    admin {
      geocode(id: $id) {
        id
        date
        date_shipped
        email
        number
        shipped
        shipping_city
        shipping_country
        shipping_country_code
        shipping_instructions
        shipping_name
        shipping_state
        total_subtotal
        tracking_code
        tracking_url

        delivered
        tracking_history
        tracking_status
        latitude
        longitude
        cost
        profit

        items {
          quantity
        }
      }
    }
  }
`;

export const UPSERT_ORDER_MUTATION = gql`
  mutation upsertOrder($orderInput: OrderInput!) {
    admin {
      upsertOrder(orderInput: $orderInput) {
        id
        company_title
        date
        date_shipped
        discount_code
        email
        message
        number
        payment
        phone
        refunded
        shipped
        shipping_city
        shipping_country
        shipping_country_code
        shipping_instructions
        shipping_name
        shipping_postcode
        shipping_service
        shipping_state
        shipping_street
        total
        total_ccfee
        total_discount
        total_kickback
        total_seller
        total_shipping
        total_subtotal
        total_tindiefee
        tracking_code
        tracking_url
        delivered
        tracking_history
        tracking_status
        latitude
        longitude
        cost
        profit
        shipping_cost
        items {
          model_number
          options
          pre_order
          price_total
          price_unit
          product
          quantity
          sku
          status
        }
      }
    }
  }
`;

export const ORDER_MAP_PINS = gql`
  query orderMapPins {
    admin {
      orderMapPins {
        lat
        lng
      }
    }
  }
`;
