import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PingMap from './PingMap';
import PingsTable from './PingsTable';
import DevicesTable from './DevicesTable';

function Orders() {
  return (
    <Switch>
      <Route path="/pings/devices" component={DevicesTable} />
      <Route path="/pings/map" component={PingMap} />
      <Route path="/pings" component={PingsTable} />
    </Switch>
  );
}

export default Orders;
