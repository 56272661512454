import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ItemsTable from './ItemsTable';
import ItemDetail from './ItemDetail';

function Items() {
  return (
    <Switch>
      <Route path="/items/:id" component={ItemDetail} />
      <Route path="/items" component={ItemsTable} />
    </Switch>
  );
}

export default Items;
