import React from 'react';
import ReactPaginate from 'react-paginate';

interface PageInfo {
  current?: number;
  size: number;
  total: number;
}

interface Props {
  pageInfo: PageInfo;
  page: number;
  onPageChange: (selected: number) => void;
}

function Pagination(props: Props) {
  const { pageInfo, page, onPageChange } = props;
  const { total, size } = pageInfo || {};

  const currentPageIndex = page - 1;
  const firstItemIndex = currentPageIndex * size;
  const lastItemIndex = firstItemIndex + size - 1;
  const pageCount = total / size;

  if (!pageCount || pageCount <= 1) return null;

  return (
    <nav>
      <ReactPaginate
        marginPagesDisplayed={2}
        initialPage={currentPageIndex}
        pageCount={pageCount}
        onPageChange={data => onPageChange(data.selected + 1)}
        disableInitialCallback={true}
        containerClassName="pagination pagination-sm"
        breakClassName="page-item"
        breakLabel={<span className="page-link">...</span>}
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
        disabledClassName="disabled"
        activeLinkClassName="active"
        activeClassName="active"
        pageLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
      />
      <ul className="pagination pagination-sm">
        <li className="page-item disabled">
          <span className="page-link">
            Items {firstItemIndex + 1} through {lastItemIndex + 1} of {total}
          </span>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
