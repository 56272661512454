import React from 'react';

export interface PropertyListProps {
  object: any;
  nest?: boolean;
}

function formatText(text) {
  if (!text)
    return text;

  let textWithoutUnderscores = text;
  while (textWithoutUnderscores.includes('_')) {
    textWithoutUnderscores = textWithoutUnderscores.replace('_', ' ');
  }

  return textWithoutUnderscores;
}

function renderProperty(object: any, propertyName: string, nest?: boolean) {
  const title = formatText(propertyName);
  let value = object[propertyName];



  if (typeof value === 'boolean')
    value = value ? 'True' : 'False';

  else if (typeof value === 'number' && propertyName !== 'id')
    value = value.toLocaleString();

  else if (value instanceof Date)
    value = value.toLocaleString();

  else if (value === null)
    value = '';

  else if (typeof value === 'string' && propertyName.endsWith('xml')) {
    value = (<pre><code>{value.replace(new RegExp('\\t', 'g'), '  ')}</code></pre>);
  }

  else if (typeof value === 'object') {

    // if someone assigned 'foo: <span>bar</span>' just render the property
    const isSymbol = value && value['$$typeof'] && typeof value['$$typeof'] === 'symbol';

    // could be an array of elements as well (probably a better way to deal with this)
    // the fix could be a level up, by simply making sure the property contains a single element, like a render method requires...
    const isArrayOfSymbols = Array.isArray(value) && value[0] && value[0]['$$typeof'] && typeof value[0]['$$typeof'] === 'symbol';

    if (isSymbol) {
      // do nothing, just render the value as-is
      // no-self-assign

    } else if (isArrayOfSymbols) {
      value = (
        <div>
          { value.map(inner => { return inner; }) }
        </div>
      );

    } else if (nest) {
      value = (
        <div>
          <br/>
          <PropertyList object={value} nest={nest}/>
        </div>
      );

    } else {

      try {
        value = (
          <pre><code>{JSON.stringify(value, null, 2)}</code></pre>
        );
      } catch { }
    }
  }


  if (!value)
    return null;

  return (
    <tr key={title}>
      <td className="text-capitalize text-right border-0">{title}:</td>
      <td className="pl-2 border-0">{value}</td>
    </tr>
  );
}

function PropertyList(props: PropertyListProps) {
  const { object, nest } = props;
  const propertyNames = Object.getOwnPropertyNames(object);

  const items = propertyNames.map((propertyName) => {
    return renderProperty(object, propertyName, nest);
  });

  return (
    <table className="table table-sm table-responsive mb-0">
      <tbody>
      {items}
      </tbody>
    </table>
  );
}

export default PropertyList;
