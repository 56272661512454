import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Items from './Items';
import NotFound from './NotFound';
import Orders from './Orders/Orders';
import Pings from './Pings';

function Routes() {
  return (
    <Switch>
      <Route path="/orders" component={Orders} />
      <Route path="/pings" component={Pings} />
      <Route path="/items" component={Items} />
      <Route exact path="/" render={() => <Redirect to="/orders" />} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default Routes;
