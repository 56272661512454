import React from 'react';
import { useQuery } from 'react-apollo';
import { Table } from 'react-bootstrap';
import { Chart } from 'react-charts';
import { firstBy } from 'thenby';
import { countries } from 'utils/countries';
import { currency } from 'utils/format';
import { groupBy } from 'utils/groupBy';
import BreadcrumbBar from '../BreadcrumbBar';
import ErrorAlert from '../ErrorAlert';
import LocaleDate from '../LocaleDate';
import { ORDERS_QUERY } from './queries';

function OrderStats() {
  const [orders, setOrders] = React.useState<Array<any>>([]);
  const [items, setItems] = React.useState<Array<any>>([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = React.useState();

  const { loading, error, data } = useQuery(ORDERS_QUERY, {
    variables: { lastEvaluatedKey },
    notifyOnNetworkStatusChange: true
  });
  const { items: ordersPage, lastEvaluatedKey: currentLastEvaluatedKey } = data?.admin?.orders || {};

  React.useEffect(() => {
    if (ordersPage) {
      const combined = [...orders, ...ordersPage];
      var flags = {};
      var unique = combined.filter((order) => {
        if (flags[order.number]) {
          return false;
        }
        flags[order.number] = true;
        return true;
      });
      setOrders(unique);

      const itemsPage: any[] = [];
      ordersPage.forEach((order) => itemsPage.push(...order.items));
      const combinedItems = [...items, ...itemsPage];
      setItems(combinedItems);
    }
  }, [ordersPage]);

  React.useEffect(() => {
    if (currentLastEvaluatedKey) {
      setLastEvaluatedKey(currentLastEvaluatedKey);
    }
  }, [currentLastEvaluatedKey]);

  if (orders) orders.sort((a, b) => b.number - a.number);

  orders?.forEach((order) => {
    const country = countries.find((country) => country.twoLetterCountryCode === order.shipping_country_code);
    order.continent_name = country?.continentName;
    order.continent_code = country?.continentCode;
  });

  const count = orders?.length;
  const undeliveredCount = orders?.filter((o) => !o.delivered).length;
  const continents = groupBy(orders, 'continent_name');
  const continentsCount = continents && Object.entries(continents).length;
  const countriesGroup = groupBy(orders, 'shipping_country');
  const countriesCount = countriesGroup && Object.entries(countriesGroup).length;
  const names = groupBy(orders, 'shipping_name');
  const namesCount = names && Object.entries(names).length;
  const emails = groupBy(orders, 'email');
  const emailsCount = emails && Object.entries(emails).length;
  const cities = groupBy(orders, 'shipping_city');
  const citiesCount = cities && Object.entries(cities).length;
  const states = groupBy(orders, 'shipping_state');
  const statesCount = states && Object.entries(states).length;
  const itemsCount = orders?.reduce((total, current) => total + current.items.length, 0);
  const total = orders?.reduce((total, current) => total + current.total_subtotal, 0);
  const tindie = orders?.reduce((total, current) => total + current.total_tindiefee, 0);
  const shipping = orders?.reduce((total, current) => total + current.shipping_cost, 0);
  const parts = orders?.reduce((total, current) => total + current.cost, 0);
  const profit = orders?.reduce((total, current) => total + current.profit, 0);

  const totalContinents = Object.entries(groupBy(countries, 'continentName')).length;
  const continentsPercent = ((continentsCount / totalContinents) * 100).toFixed(0);

  const totalCountries = countries.length;
  const countriesPercent = ((countriesCount / countries.length) * 100).toFixed(0);

  const usStates = groupBy(
    orders?.filter((o) => o.shipping_country_code === 'US'),
    'shipping_state'
  );
  const usStatesCount = usStates && Object.entries(usStates).length;

  const ordersByMonth = groupBy(orders, (item) => {
    const date = new Date(item.date);
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-01`;
  });

  const months: any[] = [];

  ordersByMonth &&
    Object.keys(ordersByMonth).forEach((month) => {
      const monthOrders = ordersByMonth[month];
      months.push({
        isTotalRow: true,
        number: monthOrders.length,
        date: month,
        undelivered: monthOrders.filter((o) => !o.delivered).length,
        continents: Object.entries(groupBy(monthOrders, 'continent_name')).length,
        countries: Object.entries(groupBy(monthOrders, 'shipping_country')).length,
        names: Object.entries(groupBy(monthOrders, 'shipping_name')).length,
        emails: Object.entries(groupBy(monthOrders, 'email')).length,
        cities: Object.entries(groupBy(monthOrders, 'shipping_city')).length,
        states: Object.entries(groupBy(monthOrders, 'shipping_state')).length,
        items: monthOrders?.reduce((total, current) => total + current.items.length, 0),
        total: monthOrders?.reduce((total, current) => total + current.total_subtotal, 0),
        profit: monthOrders?.reduce((total, current) => total + current.profit, 0)
      });
    });

  const ordersByYear = groupBy(orders, (item) => `${new Date(item.date).getFullYear()}-01-01`);

  const years: any[] = [];

  ordersByYear &&
    Object.keys(ordersByYear).forEach((year) => {
      const yearOrders = ordersByYear[year].filter((o) => !o.isTotalRow);
      years.push({
        isTotalRow: true,
        number: yearOrders.length,
        date: year,
        undelivered: yearOrders.filter((o) => !o.delivered).length,
        continents: Object.entries(groupBy(yearOrders, 'continent_name')).length,
        countries: Object.entries(groupBy(yearOrders, 'shipping_country')).length,
        names: Object.entries(groupBy(yearOrders, 'shipping_name')).length,
        emails: Object.entries(groupBy(yearOrders, 'email')).length,
        cities: Object.entries(groupBy(yearOrders, 'shipping_city')).length,
        states: Object.entries(groupBy(yearOrders, 'shipping_state')).length,
        items: yearOrders?.reduce((total, current) => total + current.items.length, 0),
        total: yearOrders?.reduce((total, current) => total + current.total_subtotal, 0),
        tindie: yearOrders?.reduce((total, current) => total + current.total_tindiefee, 0),
        shipping: yearOrders?.reduce((total, current) => total + current.shipping_cost, 0),
        parts: yearOrders?.reduce((total, current) => total + current.cost, 0),
        profit: yearOrders?.reduce((total, current) => total + current.profit, 0)
      });
    });

  // months?.sort(firstBy('date', 'desc'));

  // years?.sort(firstBy('date', 'desc'));

  const yearChartData = React.useMemo(
    () => [
      {
        label: 'Profit by Year',
        data: years?.sort(firstBy('date')).map((yearRow) => ({
          primary: new Date(yearRow.date).getUTCFullYear(),
          secondary: yearRow.profit
        }))
      },
      {
        label: 'Sales by Year',
        data: years?.sort(firstBy('date')).map((yearRow) => ({
          primary: new Date(yearRow.date).getUTCFullYear(),
          secondary: yearRow.total
        }))
      }
    ],
    [years]
  );

  const monthChartData = React.useMemo(
    () => [
      {
        label: 'Profit by Month',
        data: months?.sort(firstBy('date')).map((monthRow) => {
          const date = new Date(monthRow.date);
          return {
            primary: `${date.getUTCFullYear()}-${('0' + (date.getUTCMonth() + 1)).slice(-2)}`,
            secondary: monthRow.profit
          };
        })
      },
      {
        label: 'Sales by Month',
        data: months?.sort(firstBy('date')).map((monthRow) => {
          const date = new Date(monthRow.date);
          return {
            primary: `${date.getUTCFullYear()}-${('0' + (date.getUTCMonth() + 1)).slice(-2)}`,
            secondary: monthRow.total
          };
        })
      }
    ],
    [months]
  );

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'bottom' },
      { type: 'linear', position: 'left' }
    ],
    []
  );

  const breadcrumbBar = (
    <BreadcrumbBar items={[{ text: 'Admin', to: '/' }, { text: 'Orders', to: '/orders' }, { text: 'Stats' }]} />
  );

  const spinner = loading && (
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );

  const yearsTable = !error && orders && (
    <>
      <h5>Years</h5>
      <div
        style={{
          width: '800px',
          height: '300px'
        }}
      >
        <Chart data={yearChartData} axes={axes} tooltip />
      </div>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>Year</th>
            <th>Orders</th>
            <th>Undelivered</th>
            <th>Continents</th>
            <th>Countries</th>
            <th>Names</th>
            <th>Emails</th>
            <th>Cities</th>
            <th>States</th>
            <th>Items</th>
            <th>Total</th>
            <th>Tindie</th>
            <th>Shipping</th>
            <th>Parts</th>
            <th>Profit</th>
          </tr>
        </thead>
        <tbody>
          {[...years].sort(firstBy('date', 'desc')).map((row, index) => {
            const year = new Date(row.date).getUTCFullYear();
            return (
              <tr key={index}>
                <td>{year}</td>
                <td>{row.number.toLocaleString()}</td>
                <td>{row.undelivered?.toLocaleString()}</td>
                <td>{row.continents?.toLocaleString()}</td>
                <td>{row.countries?.toLocaleString()}</td>
                <td>{row.names?.toLocaleString()}</td>
                <td>{row.emails?.toLocaleString()}</td>
                <td>{row.cities?.toLocaleString()}</td>
                <td>{row.states?.toLocaleString()}</td>
                <td>{row.items?.toLocaleString()}</td>
                <td>{currency.format(row.total)}</td>
                <td>{currency.format(row.tindie)}</td>
                <td>{currency.format(row.shipping)}</td>
                <td>{currency.format(row.parts)}</td>
                <td>{currency.format(row.profit)}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th>{years.length}</th>
            <th>{count.toLocaleString()}</th>
            <th>{undeliveredCount.toLocaleString()}</th>
            <th
              title={`${continentsCount.toLocaleString()}/${totalContinents.toLocaleString()} (${continentsPercent}%)`}
            >
              {continentsCount.toLocaleString()}
            </th>
            <th title={`${countriesCount.toLocaleString()}/${totalCountries.toLocaleString()} (${countriesPercent}%)`}>
              {countriesCount.toLocaleString()}
            </th>
            <th>{namesCount.toLocaleString()}</th>
            <th>{emailsCount.toLocaleString()}</th>
            <th>{citiesCount.toLocaleString()}</th>
            <th title={`${usStatesCount.toLocaleString()} US States`}>{statesCount.toLocaleString()}</th>
            <th>{itemsCount.toLocaleString()}</th>
            <th>{currency.format(total)}</th>
            <th>{currency.format(tindie)}</th>
            <th>{currency.format(shipping)}</th>
            <th>{currency.format(parts)}</th>
            <th>{currency.format(profit)}</th>
          </tr>
        </tfoot>
      </Table>
    </>
  );

  const monthsTable = !error && orders && (
    <>
      <h5>Months</h5>{' '}
      <div
        style={{
          width: '800px',
          height: '300px'
        }}
      >
        <Chart data={monthChartData} axes={axes} tooltip />
      </div>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>Month</th>
            <th>Orders</th>
            <th>Undelivered</th>
            <th>Continents</th>
            <th>Countries</th>
            <th>Names</th>
            <th>Emails</th>
            <th>Cities</th>
            <th>States</th>
            <th>Items</th>
            <th>Total</th>
            <th>Profit</th>
          </tr>
        </thead>
        <tbody>
          {[...months].sort(firstBy('date', 'desc')).map((row, index) => {
            return (
              <tr key={index}>
                <td>
                  <LocaleDate date={row.date} />
                </td>
                <td>{row.number.toLocaleString()}</td>
                <td>{row.undelivered?.toLocaleString()}</td>
                <td>{row.continents?.toLocaleString()}</td>
                <td>{row.countries?.toLocaleString()}</td>
                <td>{row.names?.toLocaleString()}</td>
                <td>{row.emails?.toLocaleString()}</td>
                <td>{row.cities?.toLocaleString()}</td>
                <td>{row.states?.toLocaleString()}</td>
                <td>{row.items?.toLocaleString()}</td>
                <td>{currency.format(row.total)}</td>
                <td>{currency.format(row.profit)}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th>{months.length}</th>
            <th>{count.toLocaleString()}</th>
            <th>{undeliveredCount.toLocaleString()}</th>
            <th
              title={`${continentsCount.toLocaleString()}/${totalContinents.toLocaleString()} (${continentsPercent}%)`}
            >
              {continentsCount.toLocaleString()}
            </th>
            <th title={`${countriesCount.toLocaleString()}/${totalCountries.toLocaleString()} (${countriesPercent}%)`}>
              {countriesCount.toLocaleString()}
            </th>
            <th>{namesCount.toLocaleString()}</th>
            <th>{emailsCount.toLocaleString()}</th>
            <th>{citiesCount.toLocaleString()}</th>
            <th title={`${usStatesCount.toLocaleString()} US States`}>{statesCount.toLocaleString()}</th>
            <th>{itemsCount.toLocaleString()}</th>
            <th>{currency.format(total)}</th>
            <th>{currency.format(profit)}</th>
          </tr>
        </tfoot>
      </Table>
    </>
  );

  // const skus = groupBy(items, 'sku');
  const models = groupBy(items, 'model_number');
  const products = groupBy(items, 'product');
  // console.log({models});
  // const options = groupBy(items, 'options');

  // console.log({skus, models, options});

  const modelsArray = Object.keys(models).map((key) => ({ key, count: models[key].length }));
  modelsArray?.sort(firstBy('count', 'desc'));

  const productsArray = Object.keys(products).map((key) => ({ key, count: products[key].length }));
  productsArray?.sort(firstBy('count', 'desc'));

  const modelsTable = !error && models && (
    <>
      <h5>Models</h5>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>Model</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {modelsArray.map((model, index) => {
            return (
              <tr key={index}>
                <td>{model.key}</td>
                <td>{model.count.toLocaleString()}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );

  const productsTable = !error && products && (
    <>
      <h5>Products</h5>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>Product</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {productsArray.map((product, index) => {
            return (
              <tr key={index}>
                <td>{product.key}</td>
                <td>{product.count.toLocaleString()}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );

  const errorAlert = error && <ErrorAlert error={error} />;

  return (
    <>
      {breadcrumbBar}
      {errorAlert}
      {yearsTable}
      {productsTable}
      {modelsTable}
      {monthsTable}
      {spinner}
    </>
  );
}

export default OrderStats;
