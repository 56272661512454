import { Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import BreadcrumbBar from '../BreadcrumbBar';
import ErrorAlert from '../ErrorAlert';
import Pagination from '../Pagination';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const ITEMS_QUERY = gql`
  query items(
    $id: [Int]
    $search: String
    $pageNumber: Int
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
  ) {
    admin {
      items(
        id: $id
        search: $search
        pageNumber: $pageNumber
        pageSize: $pageSize
        sortBy: $sortBy
        sortDirection: $sortDirection
      ) {
        pageInfo {
          total
          current
          size
          sortDirection
          sortBy
        }
        nodes {
          id
          cost
          modelNumber
          options
          product
          sku
        }
      }
    }
  }
`;

function useSearchParams() {
  return new URLSearchParams(useLocation().search);
}

function ItemsTable() {
  const history = useHistory();
  const searchParams = useSearchParams();
  const page = parseInt(searchParams.get('page') || '', 10) || undefined;
  const search = searchParams.get('search') || undefined;
  const sortBy = searchParams.get('sortBy') || undefined;
  const sortDirection = searchParams.get('sortDirection')?.toUpperCase() || undefined;

  const pageClick = (pageNumber) => {
    searchParams.set('page', pageNumber);
    history.push(`/items?${searchParams.toString()}`);
  };

  const { loading, error, data } = useQuery(ITEMS_QUERY, {
    variables: { pageNumber: page, search, sortBy, sortDirection }
  });
  const { items } = data?.admin || {};
  const { nodes, pageInfo } = items || {};

  const breadcrumbBar = <BreadcrumbBar items={[{ text: 'Admin', to: '/' }, { text: 'Items' }]} />;

  const spinner = loading && (
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );

  const onSort = (field, order) => {
    order = sortDirection?.toLowerCase() === 'asc' ? 'desc' : 'asc';
    searchParams.set('sortBy', field);
    searchParams.set('sortDirection', order);
    history.push(`/items?${searchParams.toString()}`);
  };

  // cost
  // modelNumber
  // options
  // product

  const columns = [
    {
      dataField: 'sku',
      text: 'SKU',
      sort: true,
      onSort: onSort,
      formatter: (cell, row) => <Link to={`/items/${row.id}`}>{cell}</Link>
    },
    {
      dataField: 'product',
      text: 'Product',
      sort: true,
      onSort: onSort
    },
    {
      dataField: 'modelNumber',
      text: 'Model',
      sort: true,
      onSort: onSort
    },
    {
      dataField: 'options',
      text: 'Options',
      sort: true,
      onSort: onSort
    },
    {
      dataField: 'cost',
      text: 'Cost',
      sort: true,
      onSort: onSort,
      formatter: (cell) => `$${cell}`
    }
  ];

  // const defaultSorted = [{
  //   dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
  //   order: 'desc' // desc or asc
  // }];

  const table = !error && nodes?.length && (
    <>
      <Formik
        initialValues={{ search }}
        onSubmit={(values, actions) => {
          try {
            if (values.search) searchParams.set('search', values.search);
            else searchParams.delete('search');
            searchParams.delete('page');
            history.push(`/items?${searchParams.toString()}`);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, handleChange, isSubmitting, handleBlur }) => (
          <form className="form-inline mb-1" onSubmit={handleSubmit}>
            <input
              className="form-control mr-sm-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              name="search"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <button className="btn btn-outline-success my-2 my-sm-0" type="submit" disabled={loading || isSubmitting}>
              Search
            </button>
          </form>
        )}
      </Formik>

      <div className="table-responsive">
        <BootstrapTable
          bootstrap4={true}
          bordered={false}
          remote={true}
          classes="table table-striped table-hover table-sm"
          keyField="id"
          data={nodes}
          columns={columns}
          // defaultSorted={defaultSorted}
        />
      </div>

      <Pagination pageInfo={pageInfo} page={page || 1} onPageChange={pageClick} />
    </>
  );

  const errorAlert = error && <ErrorAlert error={error} />;

  return (
    <>
      {breadcrumbBar}
      {errorAlert}
      {table}
      {spinner}
    </>
  );
}

export default ItemsTable;
