import { Field, Form, Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';
import BreadcrumbBar from '../BreadcrumbBar';
import ErrorAlert from '../ErrorAlert';
import { UPSERT_ORDER_MUTATION } from './queries';
import { currency } from 'utils/format';

const ORDER_QUERY = gql`
  query order($id: Int!) {
    admin {
      order(id: $id) {
        id
        company_title
        date
        date_shipped
        discount_code
        email
        message
        number
        payment
        phone
        refunded
        shipped
        shipping_city
        shipping_country
        shipping_country_code
        shipping_instructions
        shipping_name
        shipping_postcode
        shipping_service
        shipping_state
        shipping_street
        total
        total_ccfee
        total_discount
        total_kickback
        total_seller
        total_shipping
        total_subtotal
        total_tindiefee
        tracking_code
        tracking_url
        delivered
        tracking_history
        tracking_status
        latest_event_date
        latitude
        longitude
        cost
        profit
        shipping_cost
        items {
          model_number
          options
          pre_order
          price_total
          price_unit
          product
          quantity
          sku
          status
        }
      }
    }
  }
`;

interface Props extends RouteComponentProps<{ id: string }> {}

function OrderDetail(props: Props) {
  const id = parseInt(props.match.params.id, 10);
  const { loading, error, data } = useQuery(ORDER_QUERY, {
    variables: { id: id }
  });
  const { order } = data?.admin || {};

  const [upsertOrder, { error: upsertError, called: upsertCalled }] = useMutation(UPSERT_ORDER_MUTATION);

  const breadcrumbBar = (
    <BreadcrumbBar items={[{ text: 'Admin', to: '/' }, { text: 'Orders', to: '/orders' }, { text: `${id}` }]} />
  );

  const spinner = loading && (
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );

  const form = order && (
    <Formik
      initialValues={order}
      onSubmit={async (values, { setSubmitting }) => {
        const { cost, shipping_cost, shipped, delivered, tracking_code, profit } = values;

        const upsertInput = {
          id: order.id,
          number: order.number,
          shipped,
          delivered,
          tracking_code,
          shipping_cost,
          cost,
          profit
        };

        await upsertOrder({ variables: { orderInput: upsertInput } });

        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting, setFieldValue }) => {
        const { cost, items, shipping_cost, total_seller } = values;

        const raw = (
          <pre>
            <code>{JSON.stringify(values, null, 2)}</code>
          </pre>
        );

        const calculatedProfit = Math.round((total_seller - cost - shipping_cost) * 100) / 100;
        let calculatedCost = 0;

        for (let orderItem of items || []) {
          for (let item of orderItem.items || []) {
            calculatedCost += item.cost;
          }
        }

        return (
          <>
            <Form>
              <div className="form-group row">
                <div className="col-sm-2">Shipping</div>
                <div className="col-sm-10">
                  <div className="form-check">
                    <Field type="checkbox" id="shipped" name="shipped" className="form-check-input" />
                    <label className="form-check-label" htmlFor="shipped">
                      Shipped
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-sm-2" />
                <div className="col-sm-10">
                  <div className="form-check">
                    <Field type="checkbox" id="delivered" name="delivered" className="form-check-input" />
                    <label className="form-check-label" htmlFor="delivered">
                      Delivered
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="tracking_code" className="col-sm-2 col-form-label">
                  Tracking Code
                </label>
                <div className="col-sm-10">
                  <div className="input-group">
                    <Field type="text" name="tracking_code" className="form-control" />
                    <div className="input-group-append">
                      <a
                        className="btn btn-outline-secondary"
                        href={`https://tools.usps.com/go/TrackConfirmAction?tLabels=9400109205568638668198`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fas fa-external-link-alt" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="shipping_cost" className="col-sm-2 col-form-label">
                  Shipping Cost
                </label>
                <div className="col-sm-10">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <Field type="number" name="shipping_cost" step=".01" className="form-control" />
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="cost" className="col-sm-2 col-form-label">
                  Cost
                </label>
                <div className="col-sm-10">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <Field type="number" name="cost" step=".01" className="form-control" />
                    <div className="input-group-append">
                      <span className="input-group-text">{currency.format(calculatedCost)}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="profit" className="col-sm-2 col-form-label">
                  Profit
                </label>
                <div className="col-sm-10">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <Field type="number" name="profit" step=".01" className="form-control" />
                    <div className="input-group-append">
                      <span className="input-group-text" onClick={() => setFieldValue('profit', calculatedProfit)}>
                        {currency.format(calculatedProfit)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-sm-10 offset-sm-2">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    Save
                  </button>

                  {!upsertError && upsertCalled && <span className="ml-2 text-success">Order saved</span>}
                </div>
              </div>
            </Form>
            Tindie:{' '}
            <a
              href={`https://www.tindie.com/orders/${order.number}`}
              target="_blank"
              rel="noopener noreferrer"
              title="Open Tindie order in new tab"
            >
              {order.number} <i className="fas fa-external-link-alt"></i>{' '}
            </a>
            <a
              href={`/orders/${order.number}#`}
              onClick={() => {
                window.open(`https://www.tindie.com/orders/${order.number}`, '_blank');
                window.open(`https://www.tindie.com/orders/print/${order.number}`, '_blank');
                window.open('https://ship.pirateship.com/ship/single');
              }}
            >
              <i className="fas fa-print" />{' '}
            </a>
            <a
              id="copy"
              href={`/orders/${order.number}#`}
              onClick={() => {
                const div = document.getElementById('address');
                if (div) {
                  const text = div.innerText;
                  const textArea = document.createElement('textarea');
                  textArea.width = '1px';
                  textArea.height = '1px';
                  textArea.background = 'transparents';
                  textArea.value = text;
                  document.body.append(textArea);
                  textArea.select();
                  document.execCommand('copy'); // No i18n
                  document.body.removeChild(textArea);
                  document.getElementById('copied')?.setAttribute('style', '');
                }
              }}
            >
              <i className="far fa-copy"></i>
            </a>
            <span id="copied" className="text-success" style={{ display: 'none' }}>
              {' '}
              Copied!
            </span>
            <div id="address">
              {order.shipping_name}
              <br />
              {order.company_title}
              {order.company_title && <br />}
              {order.shipping_street}
              <br />
              {order.shipping_city}, {order.shipping_state} {order.shipping_postcode}
              <br />
              {order.shipping_country}
              <br />
              {order.email}
              <br />
              {order.phone}
              <br />
            </div>
            {raw}
          </>
        );
      }}
    </Formik>
  );

  const errorAlert = error && <ErrorAlert error={error} />;

  const upsertErrorAlert = upsertError && <ErrorAlert error={upsertError} />;

  return (
    <>
      {breadcrumbBar}
      {errorAlert}
      {upsertErrorAlert}
      {form}
      {spinner}
    </>
  );
}

export default OrderDetail;
