export enum Env {
  Local = 'local',
  // Dev = 'dev',
  // Staging = 'stage',
  Prod = 'prod'
}

export const APP_ENV: string | undefined = process.env.REACT_APP_ENV;

export const GRAPHQL_URL: string = (() => {
  // allow for a env override
  if (process.env.REACT_APP_GRAPHQL_URL) return process.env.REACT_APP_GRAPHQL_URL;

  switch (APP_ENV) {
    // case Env.Dev:
    //   return 'https://admin.dev2.disruptops.com/internet-graphql';
    // case Env.Staging:
    //   return 'https://admin.staging.disruptops.com/internet-graphql';
    case Env.Prod:
      // default:
      return 'https://apis.evilgeniuslabs.org/internet-graphql';
    default:
      // local
      return 'http://localhost:3341/internet-graphql';
  }
})();

export const APP_ENV_REGION: string = (() => {
  // if (APP_ENV === Env.Staging)
  //   return 'us-east-1';
  return 'us-east-1';
})();

export const config = {
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://apis.evilgeniuslabs.org/internet-graphql'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_IiRiHhSOS',
    APP_CLIENT_ID: '72bjnjcr81qg9p92jlj7nhhluf',
    IDENTITY_POOL_ID: 'us-east-1:43064718-9a46-4fc5-a437-271db6aa65f9'
  }
};

export const amplifyConfig = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: 'prod-egl-api',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
};
