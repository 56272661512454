import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbBarItem {
  text: string;
  to?: string;
}

interface Props {
  items: BreadcrumbBarItem[];
}

function BreadcrumbBar(props: Props) {
  const { items } = props;
  const { length } = items || [];

  let titles = ['Evil Genius Labs'];
  if (items) titles = titles.concat(items.map(item => item.text)).reverse();
  const documentTitle = titles.join(' | ');

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);
  
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb mb-1">
        {items?.map((item, index) => {
          const { text, to } = item;
          const active = index === length - 1;
          const className = `breadcrumb-item${active ? ' active' : ''}`;
          const ariaCurrent = active && 'page';
          return (
            <li key={`li-${index}`} className={className} aria-current={ariaCurrent}>
              {to ? <Link to={to}>{text}</Link> : text}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

export default BreadcrumbBar;
