import React from 'react';
import Moment from 'react-moment';

interface Props {
  date: string;
}

function LocaleDate(props: Props) {
  const { date: dateTime } = props;

  if (!dateTime)
    return null;

  const displayDateTime = ensureDateTimeIsDate(dateTime);

  return <Moment withTitle format="l" titleFormat="lll">{displayDateTime.toLocaleString()}</Moment>;
}

function ensureDateTimeIsDate(dateTime: Date | string) {
  return dateTime;
  // return typeof dateTime === 'string' ? new Date(dateTime) : dateTime;
}

export default LocaleDate;
