import { Auth } from 'aws-amplify';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  ICognitoUserSessionData
} from 'amazon-cognito-identity-js';
import { useEffect, useState } from 'react';
import cache from './graphql/cache';
import graphqlClient from './graphql/client';
import { CognitoUser } from 'amazon-cognito-identity-js';

/**
 * Returns the value to use in the Authorization header for DisruptOps APIs (v2 and v3).
 * If there is no current user session (i.e. not signed in), or some other error occurs, returns null.
 */
export async function getAuthorizationHeader(): Promise<string | null> {
  try {
    const session = await Auth.currentSession();
    // We use the ID token, as opposed to the access token, because Cognito only allows adding custom claims to ID tokens.
    // And we don't prefix the header with "Bearer " because AppSync does not support it (https://github.com/aws/aws-appsync-community/issues/96).
    return session.getIdToken().getJwtToken();
  } catch (e) {
    console.warn('Failed to get authorization header:', e);
    return null;
  }
}

export function getIdentity(): any {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((data) => {
        if (!data) return resolve(createEmptyIdentity());
        const { username, pool, attributes } = data;
        const { email } = attributes;
        const { userPoolId } = pool;

        const identity = {
          user_id: username,
          email: email,
          user_pool_id: userPoolId,
          __typename: 'Identity'
        };

        resolve(identity);
      })
      .catch((err) => {
        resolve(createEmptyIdentity());
      });
  }).then((identity) => {
    //This is structured like this so that we can update the cache no matter the results (empty or not)
    return new Promise((resolve, reject) => {
      updateIdentityInCache(identity);
      resolve(identity);
    });
  });
}

export async function signOut(redirect: boolean = true) {
  localStorage.removeItem('ws_token');
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('redirectAfterLogin');

  updateIdentityInCache(createEmptyIdentity());
  await Auth.signOut();

  // We have to call both of these because clearStore
  // does not trigger the `onResetStore` callback.
  // Calling resetStore alone will refetch all the queries
  // Which is not what you want when signing out.
  await graphqlClient.clearStore();

  // this is erroring out when signOut is called from the /invite page
  // it's causing a gql query to be executed, which fails because we just signed out...
  try {
    await graphqlClient.resetStore();
  } catch (error) {
    console.error(error);
  }
}

function updateIdentityInCache(identity: any) {
  if (identity.custom) delete identity.custom;
  cache.writeData({
    id: 'Identity',
    data: identity || null
  });
}

export function createEmptyIdentity() {
  return {
    user_id: null,
    email: null,
    user_pool_id: null,
    __typename: 'Identity'
  };
}

/**
 * Refreshes the current user's Cognito session.
 * This will generate a new id and access token, causing the Public API authorizer to expire the user's Authorization
 * header value from it's cache, and refresh the user's DisruptOps JWT from Trinity API's token exchange.
 *
 * We should call this method anytime accounts, permissions, or projects are mutated.
 * The net effect is to ensure the behavior of the application immediately reflects the current state of authZ,
 * which is stored in that
 */
export async function refreshCognitoSession(): Promise<ICognitoUserSessionData | undefined> {
  const currentUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();

  try {
    const updatedSession = await new Promise(async (resolve, reject) => {
      currentUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
        if (err) return reject(err);
        return resolve(session);
      });
    });

    const { idToken, accessToken, refreshToken } = updatedSession as any;

    localStorage.setItem('id_token', idToken.jwtToken);
    localStorage.setItem('access_token', accessToken.jwtToken);
    localStorage.setItem('refresh_token', refreshToken ? refreshToken.token : '');

    return Promise.resolve({
      IdToken: new CognitoIdToken({ IdToken: idToken.jwtToken }),
      AccessToken: new CognitoAccessToken({ AccessToken: accessToken.jwtToken }),
      RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken.token })
    });
  } catch (err) {
    console.error(`Failed to refresh the user's Cognito session. Error: ${err.message}`);
  }

  return Promise.resolve(undefined);
}

export function useCurrentAuthenticatedUser() {
  const [user, setUser] = useState<CognitoUser | any>();
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState<boolean>();

  async function getCurrentAuthenticatedUser() {
    try {
      setLoading(true);
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      setUser(user);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCurrentAuthenticatedUser();
  }, []);

  return [user, error, loading, getCurrentAuthenticatedUser];
}
