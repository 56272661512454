import gql from 'graphql-tag';

export const PINGS = gql`
  query pings {
    admin {
      pings {
        items {
          sourceIp
          localIp
          macAddress
          millis
          deviceName
          userAgent
          createdAt
          updatedAt
          sourceIpData {
            latitude
            longitude
            city
            continent_code
            continent_name
            country_code
            country_name
            region_code
            region_name
            zip
            location {
              country_flag_emoji
            }
          }
        }
        count
        scannedCount
        lastEvaluatedKey
      }
    }
  }
`;
