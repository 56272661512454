import { onError, ErrorResponse } from 'apollo-link-error';

const errorLink = onError((errors: ErrorResponse) => {
  const { graphQLErrors } = errors;
  if (graphQLErrors) {
    graphQLErrors.forEach(err => {
      const { message, locations, path } = err;
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, err);
    });
  }

  return;
});

export default errorLink;
