import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';
import BreadcrumbBar from '../BreadcrumbBar';
import ErrorAlert from '../ErrorAlert';
import PropertyList from 'components/PropertyList';

const ORDER_ITEM_QUERY = gql`
  query items($id: [Int]) {
    admin {
      items(id: $id) {
        nodes {
          id
          cost
          modelNumber
          options
          product
          sku
        }
      }
    }
  }
`;

interface Props extends RouteComponentProps<{ id: string }> {}

function ItemDetail(props: Props) {
  const id = parseInt(props.match.params.id, 10);
  const { loading, error, data } = useQuery(ORDER_ITEM_QUERY, {
    variables: { pageNumber: 1, pageSize: 1, id: [id] }
  });
  const { nodes } = data?.admin?.items || {};
  const order = nodes && nodes[0];

  const breadcrumbBar = (
    <BreadcrumbBar items={[{ text: 'Admin', to: '/' }, { text: 'Order Items', to: '/items' }, { text: `${id}` }]} />
  );

  const spinner = loading && (
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );

  const table = !error && order && <PropertyList object={order} />;

  const errorAlert = error && <ErrorAlert error={error} />;

  return (
    <>
      {breadcrumbBar}
      {errorAlert}
      {table}
      {spinner}
    </>
  );
}

export default ItemDetail;
