import { Authenticator } from '@aws-amplify/ui-react';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.css';
// import Authenticator from './components/Authenticator';

import NavBar from './components/Navbar';
import Routes from './components/Routes';
import client from './utils/graphql/client';

function App() {
  // const [client, setClient] = useState<ApolloClient<any>>();
  // const [isAuthenticated, userHasAuthenticated] = useState(false);
  // const [isAuthenticating, setIsAuthenticating] = useState(true);

  // useEffect(() => {
  //   onLoad();
  // }, []);

  // useEffect(() => {
  //   if (isAuthenticating) {
  //     createClient();
  //   }
  // }, [isAuthenticating]);

  // async function onLoad() {
  //   try {
  //     await Auth.currentSession();
  //     userHasAuthenticated(true);
  //   } catch (e) {
  //     console.log({ e });
  //     if (e !== 'No current user') {
  //       alert(e);
  //     }
  //   }

  //   setIsAuthenticating(false);
  // }

  // async function createClient() {
  //   setClient(await getClient());
  // }

  // if (!client) {
  //   return (
  //     <div className="spinner-border" role="status">
  //       <span className="sr-only">Loading...</span>
  //     </div>
  //   );
  // }

  // if (isAuthenticating) {
  //   return (
  //     <div className="spinner-border" role="status">
  //       <span className="sr-only">Loading...</span>
  //     </div>
  //   );
  // }

  return (
    // client && (
    // <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
    <ApolloProvider client={client}>
      <Router>
        <Authenticator hideSignUp={true}>
          <div className="App">
            <header>
              <NavBar />
            </header>
            <main role="main" className="container-fluid">
              <Routes />
            </main>
          </div>
        </Authenticator>
      </Router>
    </ApolloProvider>
    // </AppContext.Provider>
    // )
  );
}

export default App;
