import React from 'react';

function NotFound() {
  return (
    <div className="alert alert-danger alert-dismissible fade show" role="alert">
      <h5 className="alert-heading">404 - Not Found</h5>
      <p className="mb-0">The page you've requested can't be found. Please double-check the url and try again.</p>
      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
}

export default NotFound;
